import { Component, OnInit } from '@angular/core'
import { AuthService } from '@app/services/auth.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'cf-new-version',
  templateUrl: './new-version.component.html',
  styleUrls: ['./new-version.component.scss']
})
export class NewVersionComponent implements OnInit {
  constructor(private nbModal: NgbModal, private auth: AuthService) {}

  ngOnInit(): void {}
  close() {
    this.nbModal.dismissAll()
  }
  hardReloadAndClose() {
    window.location.href = window.location.href + '?version=' + this.auth._version

    this.nbModal.dismissAll()
  }
}
