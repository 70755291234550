import { Component } from '@angular/core'
import { ControlAttribute } from '../../attributes/control.attribute'

@Component({
  selector: 'cf-warning-message',
  templateUrl: './warning-message.component.html',
  styleUrls: ['./warning-message.component.scss']
})
export class WarningMessageComponent extends ControlAttribute {
  constructor() {
    super()
  }
}
