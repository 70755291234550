import { Component, OnInit } from '@angular/core';
import { ControlAttribute } from '../../attributes/control.attribute';

@Component({
  selector: 'cf-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent extends ControlAttribute implements OnInit {
  constructor() {
    super()
  }

  ngOnInit(): void {}
}
