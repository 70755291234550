import { Component } from '@angular/core'
import { ControlAttribute } from '../../attributes/control.attribute'

@Component({
  selector: 'cf-break-line',
  templateUrl: './break-line.component.html',
  styleUrls: ['./break-line.component.scss']
})
export class BreakLineComponent extends ControlAttribute {
  constructor() {
    super()
  }
}
