import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'
import { ControlAttribute } from '../../attributes/control.attribute'

@Component({
  selector: 'cf-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent extends ControlAttribute implements OnInit, AfterViewInit {
  emailDomains: string[] = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'outlook.com',
    'aol.com',
    'msn.com',
    'live.com',
    'icloud.com',
    'mac.com',
    'rocketmail.com',
    'google.com',
    'contactless.io',
  ];
  
  prediction: string = '';
  constructor() {
    super()
  }

  @ViewChild('email', { read: MatAutocompleteTrigger }) autoTrigger: MatAutocompleteTrigger

  ngOnInit(): void {}
  get autocompleteList() {
    if (this.autocompleting) {
      return this.control.field.autocomplete
        .map((v) => this.control.value.split('@')[0] + '@' + v)
        .filter((v) => v.includes(this.control.value))
    } else return []
  }
  get autocompleting() {
    const endsWithAtRegex = /^([a-zA-Z]{1,}@.{2,})/g
    return endsWithAtRegex.test(this.control.value) && this.control.field.autocomplete.length > 0
  }
  ngAfterViewInit(): void {
    this.autoTrigger.panelClosingActions.subscribe(() => {
      if (this.autoTrigger.activeOption) {
        this.control.setValue(this.autoTrigger.activeOption.value)
      }
    })
  }


  onInput() {
    const value = this.control.value || '';
    const atIndex = value.indexOf('@');
    if (atIndex > -1) {
      const typedDomain = value.slice(atIndex + 1);
      this.prediction = this.getMatchingDomain(typedDomain);
    } else {
      this.prediction = '';
    }
  }

  getMatchingDomain(typedDomain: string): string {
    if (!typedDomain) return '';
    const match = this.emailDomains.find(domain =>
      domain.startsWith(typedDomain.toLowerCase())
    );
    return match ? `${this.control.value}${match.slice(typedDomain.length)}` : '';
  }

  applyPrediction(event: KeyboardEvent) {
    if (this.prediction) {
      this.control.setValue(this.prediction);
      this.prediction = '';
      event.preventDefault();
    }
  }

  clearPrediction() {
    this.prediction = '';
  }
}
