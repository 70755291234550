export const qatarFashionUnited = (settings, qrcode) => ({
  card: `
    <div class="card-wrapper">
      <div class="card">
        <div class="header">
          <img
            src="https://library.micetribe.com/custom/qacreates/qatar-fashion-united.svg"
            alt=""
          />
        </div>
        <div class="profile-and-QR">
          <div class="qr-code">${qrcode.innerHTML}</div>
          <img src="#_UPLOAD_#" alt="" />
        </div>
        <div class="full-name">
          <p>#_FULLNAME_#</p>
        </div>
        <div class="company">
          <p>#_QFU_EVENT_# | #_ORGANIZATION_#</p>
          <p class="job">#_JOB_TITLE_#</p>
        </div>
        <div class="sections">
          #_ZONE_ACCESS_#
        </div>
        <div class="category">
          <p>#_RADIO1_#</p>
        </div>
      </div>
    </div>    
  `,
  style: `
    <style>
      @font-face {
          font-family: GBR;
          src: url(https://library.micetribe.com/custom/qacreates/ITC+Avant+Garde+Gothic+Book+Regular.otf);
      }

      @font-face {
          font-family: GBM;
          src: url(https://library.micetribe.com/custom/qacreates/ITC+Avant+Garde+Gothic+Medium.otf);
      }

      * {
          font-family: GBM;
      }

      .card-wrapper {
          transform: rotateZ(180deg);
          width: 94.985416667mm;
          height: 109.98729167mm;
          display: flex;
          align-items: center;
          justify-content: center;
      }

      .card {
          padding: 10.583333333mm;
          width: 100%;
          height: 100%;
      }

      .header img {
          width: 100%;
          margin-bottom: 2.6458333333mm;
      }

      .profile-and-QR {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
      }

      .profile-and-QR img {
          width: 24.180270833mm;
          height: 24.180270833mm;
      }

      .full-name {
        width: 80%;
        margin: auto;
        height: 64px;
        overflow: hidden;
      }

      .full-name p {
        font-size: 3.6mm;
        font-weight: 400;
        color: #0d0d0d;
      }

      .company {
          width: 80%;
          margin: auto;
      }

      .company p {
          font-weight: 400;
          font-size: 2.7908514583mm;
          margin: 0;
      }

      .job {
          margin: 3px 0 !important;
      }

      .sections {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2.65mm;
          flex-wrap: wrap;
          margin-top: 7.9375mm;
          min-height: 22.561020833mm;
      }

      .box {
          height: 9.9589166667mm;
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex-direction: column;
          flex-basis: 22%;
      }

      .box p {
          margin: 0;
          color: white;
          font-size: 2.38125mm;
          font-family: GBR;
          text-align: center;
      }

      .STAGE {
          background-color: #0d0d0d;
      }

      .BACKSTAGE {
          background-color: #c33081;
      }

      .PITCH {
          background-color: #f68c13;
      }

      .VVIP {
          background-color: #71297c;
      }

      .HOSPITALITY {
          background-color: #023a88;
      }

      .SKYBOX {
          background-color: #349027;
      }

      .MEDIA {
          background-color: #f9df00;
      }

      .TECHNICAL-AREA {
          background-color: #de222d;
      }

      .TECHNICAL-AREA p {
          font-size: 7px !important;
      }

      .category p {
          margin: 0;
          font-weight: 500;
          text-align: center;
          font-size: 5.97995375mm;
          margin-top: 7.9375mm;
      }

      .print-page {
          display: block;
      }

      .page-break {
          display: block;
          page-break-before: always;
      }

      @media print {
          .page-break {
              display: block;
              page-break-before: always;
          }
      }
    </style>
  `
})