export const titles = [
  'Lead',
  'Senior',
  'Direct',
  'Corporate',
  'Dynamic',
  'Future',
  'Product',
  'National',
  'Regional',
  'District',
  'Central',
  'Global',
  'Relational',
  'Customer',
  'Investor',
  'Dynamic',
  'International',
  'Legacy',
  'Forward',
  'Interactive',
  'Internal',
  'Human',
  'Chief',
  'Principal',
  'Solutions',
  'Program',
  'Brand',
  'Security',
  'Research',
  'Marketing',
  'Directives',
  'Implementation',
  'Integration',
  'Functionality',
  'Response',
  'Paradigm',
  'Tactics',
  'Identity',
  'Markets',
  'Group',
  'Resonance',
  'Applications',
  'Optimization',
  'Operations',
  'Infrastructure',
  'Intranet',
  'Communications',
  'Web',
  'Branding',
  'Quality',
  'Assurance',
  'Impact',
  'Mobility',
  'Ideation',
  'Data',
  'Creative',
  'Configuration',
  'Accountability',
  'Interactions',
  'Factors',
  'Usability',
  'Metrics',
  'Team',
  'Supervisor',
  'Associate',
  'Executive',
  'Liason',
  'Officer',
  'Manager',
  'Engineer',
  'Specialist',
  'Director',
  'Coordinator',
  'Administrator',
  'Architect',
  'Analyst',
  'Designer',
  'Planner',
  'Synergist',
  'Orchestrator',
  'Technician',
  'Developer',
  'Producer',
  'Consultant',
  'Assistant',
  'Facilitator',
  'Agent',
  'Representative',
  'Strategist'
]

