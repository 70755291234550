
<qrcode *ngIf="data && data.qrcode_drawer"
  class="generated-qrcode"
  [elementType]="data.qrcode_drawer.format"
  [qrdata]="data.qrcode_drawer.content"
  [colorDark]="data.qrcode_drawer.dark_color"
  [width]="viewSettings.qr.width * 3.7795275591"
  errorCorrectionLevel="H"
></qrcode>

<ng-container *ngIf="isQrcodeDrawerReady">
  <ng-container *ngFor="let data of dataArr">
    <qrcode *ngIf="data.qrcode_drawer.content"
      [class]="'generated-qrcode-' + data.id"
      [elementType]="data.qrcode_drawer.format"
      [qrdata]="data.qrcode_drawer.content"
      [colorDark]="data.qrcode_drawer.dark_color"
      [width]="viewSettings.qr.width * 3.7795275591"
      errorCorrectionLevel="H"
    ></qrcode>
  </ng-container>
</ng-container>
