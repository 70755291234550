import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormsService } from '@app/modules/@micetribe/forms/services/forms.service';
import { ToastrService } from 'ngx-toastr';
import { PrintService } from '../../services/print.service';
import { RegistrationDocument } from '@app/modules/@micetribe/forms/models/registration-document.model';
import { MiceForm } from '@app/modules/@micetribe/forms/models/form.model';
import { BadgeDrawer } from './badge-drawer';
import { HeaderService } from '@app/modules/home/services/header.service';
import { settings } from '../../badge-drawer/settings';

@Component({
  selector: 'cf-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit, OnChanges {
  @Input() data: RegistrationDocument = null
  @Input() dataArr: RegistrationDocument[] = null
  @Input() form

  @Input() print = 0
  currPrint: number
  isQrcodeDrawerReady: boolean
  viewSettings: object = null;

  constructor(
    private forms: FormsService,
    private toastrService: ToastrService,
    private printService: PrintService,
    private headerService: HeaderService
  ) { }

  ngOnInit(): void {
    this.viewSettings = this.form.badgeTemplate ? JSON.parse(this.form.badgeTemplate) : this.buildBadgeTemplate(this.form)
  }

  ngDoCheck(): void {
    if (!this.currPrint || this.currPrint !== this.print) {
      this.isQrcodeDrawerReady = false

      if (this.data) this.checkData()
      else if (this.dataArr) this.checkDataArr()

      this.currPrint = this.print
    }
  }

  checkData() {
    this.data.qrcode_drawer = BadgeDrawer.getQrCodeDataFor(this.data)

    if (this.data.third_parts && this.data.third_parts.dtcm && this.data.third_parts.dtcm.barcode) {
      this.data.barcode_drawer = BadgeDrawer.getBarCodeDataFor(this.data)
    }

    setTimeout(() => {
      this.printBadge(this.data, this.form)
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.viewSettings = this.form.badgeTemplate ? JSON.parse(this.form.badgeTemplate) : this.buildBadgeTemplate(this.form)
  }

  checkDataArr() {
    this.dataArr.forEach((data: RegistrationDocument) => {
      data.qrcode_drawer = BadgeDrawer.getQrCodeDataFor(data)

      if (data.third_parts && data.third_parts.dtcm && data.third_parts.dtcm.barcode) {
        data.barcode_drawer = BadgeDrawer.getBarCodeDataFor(data)
      }
    });
    this.isQrcodeDrawerReady = true

    setTimeout(() => {
      this.printBadges(this.dataArr, this.form)
    }, 0);
  }

  printBadge(registration: RegistrationDocument, form: MiceForm) {
    const qrcode = document.querySelector('.generated-qrcode')
    const template = BadgeDrawer.getBadgeFor(registration, qrcode, form.badgeTemplate ? JSON.parse(form.badgeTemplate) : this.buildBadgeTemplate(form))
    const card = this.printService.fillCard(template.card, registration, form)

    const iframe = document.createElement('iframe')
    iframe.id = 'temp-printing-element'
    iframe.style.position = 'absolute'
    iframe.style.top = '-10000px'

    document.body.appendChild(iframe)
    iframe.contentWindow.document.open()
    var urls = `['https://fonts.googleapis.com/css2?family=${encodeURI(settings.font)}&display=swap']`;
    if (registration.workspace === 'katara' && registration.event === 'wahc23' && registration.form === 'seats') {
      urls = `[
          'https://fonts.googleapis.com/css2?family=${encodeURI(settings.font)}&display=swap',
          'https://library.micetribe.com/images/katara_wahc23.png'
        ]`
    }
    if (
      registration.workspace === 'qga' &&
      registration.event === 'cbqm27' &&
      [
        'qga-chalet',
        'qic',
        'qdf0',
        'rol0',
        'de-boer-chalet',
        'vodafone-for-qga-chaleta',
        'primepower-for-qga-chalet',
        'brandx-for-qga-chalet',
        'qph-for-qga-chalet',
        'rapid-access-for-qga-chalet'
      ].includes(registration.form)
    ) {
      let qga = [`https://fonts.googleapis.com/css2?family=${encodeURI(settings.font)}&display=swap`]
      if (registration.fields['upload']) qga.push(registration.fields['upload'].toString())
      urls = JSON.stringify(qga)
    }

    const script = ` 
        <script>
        const urls = ${urls}
        const fetchPromises = urls.map(url => fetch(url));
        Promise.all(fetchPromises)
        .then(dataArray => {
            setTimeout(() => {
                window.print()
            }, 500)
        })
        .catch(error => {
            window.print()
        });
    </script>`
    iframe.contentWindow.document.write(eval(`\`${template.style}${card}${script}\``))
    iframe.contentWindow.document.close()

    iframe.contentWindow.onafterprint = () => {
      iframe.remove()
    }

    this.forms.patchPrintRecord(registration.id).subscribe(
      (res: any) => {
        this.printService.printRecord$.next({ id: this.data.id, prints: res.document.prints })
      },
      (error) => {
        this.toastrService.error(error.error)
      }
    )
  }

  printBadges(registrations: RegistrationDocument[], form: MiceForm) {
    registrations.forEach((registration: RegistrationDocument) => {
      registration.template = BadgeDrawer.getBadgeFor(registration, document.querySelector('.generated-qrcode-' + registration.id), form.badgeTemplate ? JSON.parse(form.badgeTemplate) : this.buildBadgeTemplate(form))
    })

    const iframe = document.createElement('iframe')
    iframe.style.position = 'absolute'
    iframe.style.top = '-10000px'

    iframe.id = 'printGroup1'
    document.body.appendChild(iframe)

    iframe.contentWindow.document.open()
    iframe.contentWindow.document.write(`${registrations[0].template.style}`)
    registrations.forEach((registration) => {
      const card = this.printService.fillCard(registration.template.card, registration, form)
      iframe.contentWindow.document.write(`
        <div class="print-page">${card}</div><div class="page-break"></div>
        <script type="text/javascript">window.onload =  function(){window.print();window.onafterprint = window.close}</script>
      `)
    })
    iframe.contentWindow.document.close()

    iframe.contentWindow.onafterprint = () => {
      iframe.remove()
    }
  }

  buildBadgeTemplate(form: MiceForm) {
    const badgeTemplate = {
      "topPadding": 52,
      "persona": { "enabled": true, "fontSize": 10, "lineHeight": 10 },
      "qr": { "enabled": true, "showText": true, "width": 18, "fontSize": 2.5 },
      "fields": {}
    }
    form.formElements.forEach(element => {
      if (element.name !== 'phone' && element.name !== 'email') {
        badgeTemplate.fields[element.name] = {
          "name": `${element.name}`,
          "label": `${element.label}`,
          "ordinal": 0,
          "enabled": true,
          "fontSize": 4.5,
          "lineHeight": 4.5
        }
      }
    });
    return badgeTemplate
  }
}