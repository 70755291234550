import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HeaderService } from '@app/modules/home/services/header.service'
import { AuthService } from '@app/services/auth.service'
import { environment } from '@environments/environment'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { GroupRegistrationResponse } from '@app/modules/@micetribe/forms/models/group-registration-server.modal'
import { SearchResponse } from '../interfaces/responses/search.response.interface'
import { SearchRequest } from '../interfaces/requests/search.request.interface'
import { SearchResultIdentity } from '../models/search.result.model'
import { Event } from '@app/modules/event/interfaces/event-item.interface'
@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(private http: HttpClient, private headerService: HeaderService, private auth: AuthService) {}
  public searching$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public overlayStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  /**
   *
   * @param term$
   * @param persona$
   * @returns Observable<SearchResponse>
   */
  search(term$: Observable<string>, persona$: Observable<string>): Observable<SearchResponse> {
    return combineLatest([term$.pipe(debounceTime(250), distinctUntilChanged()), persona$.pipe(startWith(''))]).pipe(
      debounceTime(250),
      distinctUntilChanged(),
      filter(([term, persona]) => (!!term && term.length > 2) || !!persona),
      switchMap(([term, persona]) => {
        this.searching$.next(true)
        if (persona === 'only-mine' && term === '***') {
          return this.getRecentRegistrations(this.headerService.workspaceSlug, this.headerService.eventSlug, '')
        }
        const searchInOnlyMineParams = (persona === 'only-mine' && term != '***') ? {
          crew_id: this.auth.user.token,
          form: ''
        } : {}
        return this.searchEntries(Object.assign({
          keyword:  term,
          form: persona === 'all' ? '' : persona,
          workspaceSlug: this.headerService.workspaceSlug,
          eventSlug: this.headerService.eventSlug,
          perpage: '10'
        }, searchInOnlyMineParams))
      })
    )
  }
  searchEntries(request: SearchRequest): Observable<SearchResponse> {
    return this.http
      .get<SearchResponse>(`${environment.api}/v3/query/search/${request.workspaceSlug}/${request.eventSlug}`, {
        headers: new HttpHeaders({
          token: this.auth.user.token,
          appId: '2'
        }),
        params: request
      })
      .pipe(
        map((result: any) => result),
        tap(() => this.searching$.next(false))
      )
  }

  getRecentRegistrations(workspace_slug: string, event_slug: string, form_slug: string): Observable<any> {
    return this.http.get(`${environment.api}/v3/query/top-search/${workspace_slug}/${event_slug}?limit=6`, {
      headers: new HttpHeaders({
        token: this.auth.user.token,
        appId: '2'
      })
    })
  }
  getGroupRegistration(data: {
    withParent: number
    workspaceSlug: string
    eventSlug: string
    id: string
    pageNum?: number
    perPage?: number
  }): Observable<GroupRegistrationResponse> {
    let url = `${environment.api}/v3/query/parent/${data.workspaceSlug}/${data.eventSlug}/${data.id}`
    url = url + `?page=${data.pageNum ?? 1}`
    url = url + `&perpage=${data.perPage ?? 6}`
    url = url + `&show_parent=${data.withParent}`
    url = url + `&show_extra=true`
    return this.http.get<GroupRegistrationResponse>(url, {
      headers: new HttpHeaders({
        token: this.auth.user.token,
        appId: '2'
      })
    })
  }

  deleteRegistration(workspace_slug, event_slug, form_slug, registration_id): Observable<any> {
    return this.http.delete(`${environment.api}/v3/registrations/delete/${workspace_slug}/${event_slug}/${form_slug}/${registration_id}`)
  }
  public delete(identity: SearchResultIdentity) {
    return this.http.delete(
      `${environment.api}/v3/registrations/delete/${identity.workspace}/${identity.event}/${identity.form}/${identity.id}`,
      {
        headers: new HttpHeaders({
          token: this.auth.user.token,
          appId: '2'
        })
      }
    )
  }

  /**
   * Retrieve current event from the header service
   * @returns 
   */
  public getCurrentEvent(): Event{
    return this.headerService.activeEvent
  }
}
