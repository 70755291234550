<mat-form-field appearance="outline" class="phone-field">
  <mat-label [innerHtml]="control.field.label"></mat-label>
  <img matPrefix class="flag align-top" aria-hidden [src]="flag" [matTooltip]="flagCountry" />

  <input
    matInput
    type="text"
    [formControl]="control"
    autocomplete="off"
    (keypress)="onKeyPress($event)"
    (focus)="handleFocus()"
    #htmlField
  />

  <mat-hint>
    <mice-input-shortcuts></mice-input-shortcuts>
  </mat-hint>
  <mat-error>
    {{ errorMessage }}
  </mat-error>
</mat-form-field>
