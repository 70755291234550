import { Component, Input, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'

@Component({
  selector: 'mice-input-shortcuts',
  templateUrl: './input-shortcuts.component.html',
  styleUrls: ['./input-shortcuts.component.scss']
})
export class InputShortcutsComponent implements OnInit {
  @Input()
  control: FormControl
  constructor() {}

  ngOnInit(): void {}

  select(option: string) {
    this.control.setValue(option)
  }
}
