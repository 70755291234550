<mat-form-field appearance="outline">
  <mat-label [innerHtml]="control.field.label"></mat-label>
  <input matInput type="text" [formControl]="control" [matAutocomplete]="auto" autocomplete="off" />

  <mat-autocomplete #auto="matAutocomplete" [autoActiveFirstOption]="true">
    <mat-option *ngFor="let option of titleList | async" [value]="control.value.replace(searchTerm, option) + ' '">
      <span>{{ option }}</span>
    </mat-option>
  </mat-autocomplete>
  <mat-hint>
    <mice-input-shortcuts></mice-input-shortcuts>
  </mat-hint>
  <mat-error>
    {{ errorMessage }}
  </mat-error>
</mat-form-field>
