import { Component, HostListener, OnDestroy, OnInit } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { ControlAttribute } from '../../attributes/control.attribute'

@Component({
  selector: 'cf-numeric',
  templateUrl: './numeric.component.html',
  styleUrls: ['./numeric.component.scss']
})
export class NumericComponent extends ControlAttribute implements OnInit, OnDestroy {
  private _destroy$ = new Subject()
  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }
  constructor() {
    super()
  }
  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent>event

    if (
      [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode == 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.keyCode == 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+V
      (e.keyCode == 86 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.keyCode == 88 && e.ctrlKey === true) ||
      // Allow: Ctrl+Z
      (e.keyCode == 90 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39) ||
      (e.keyCode >= 96 && e.keyCode <= 105) ||
      // Allow copy, paste and select all 
      // A, c, v and alt
      e.keyCode == 91 || 
      e.keyCode == 86 ||
      e.keyCode == 88 ||
      e.keyCode == 67 ||
      e.keyCode == 65
    ) {
      // let it happen, don't do anything
      return
    }
    const ch = String.fromCharCode(e.keyCode)
    const regEx = new RegExp('^[0-9]*$')
    if (regEx.test(ch)) return
    else e.preventDefault()
  }
  private _valueChangeSub$: Subscription

  ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((currentValue) => {
      if(!currentValue) return;
      this.control.setValue(currentValue.replace(/\D/g, '').trim(), { emitEvent: false })
    })
  }
}
