<mat-form-field appearance="outline">
  <mat-label [innerHtml]="control.field.label"></mat-label>
  <div class="email-input-container">
    <input 
      (input)="onInput()"
      (keydown.ArrowRight)="applyPrediction($event)"
      (keydown.space)="applyPrediction($event)"
      (keydown.tab)="applyPrediction($event)"
      (blur)="clearPrediction()"
      matInput type="email"
      [formControl]="control" 
      [matAutocomplete]="auto"
      autocomplete="disabled" 
    #email />
    <span class="prediction-text">{{ prediction }}</span>
  </div>
    
  <mat-autocomplete #auto="matAutocomplete" [autoActiveFirstOption]="true">
    <mat-option *ngFor="let option of autocompleteList" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
  <mat-hint>
    <mice-input-shortcuts></mice-input-shortcuts>
  </mat-hint>
  <mat-error>
    {{ errorMessage }}
  </mat-error>
</mat-form-field>