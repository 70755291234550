import { Component, HostListener, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { ControlAttribute } from '../../attributes/control.attribute'
import { titles } from '../../data/jobtitles-data'

@Component({
  selector: 'cf-job-title',
  templateUrl: './job-title.component.html',
  styleUrls: ['./job-title.component.scss']
})
export class JobTitleComponent extends ControlAttribute implements OnInit, OnDestroy {
  private _destroy$ = new Subject()
  titleList = new Subject()
  searchTerm: string =""

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  constructor() {
    super()
  }

  ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((currentValue: string) => {
      if(!currentValue) return;
      this._autofillJobTitle(currentValue)
      const capitalizedFirstLetterRegex = /\b[a-z](?!\s)/g
      const nonLettersRegex = /[^a-zA-Z\s]/g
      const moreThanOneSpaceRegex = / {2,}/g
      currentValue = currentValue
        .replace(nonLettersRegex, '')
        .replace(moreThanOneSpaceRegex, ' ')
        .replace(capitalizedFirstLetterRegex, (letter) => letter.toUpperCase())
      currentValue = currentValue.startsWith(' ') ? currentValue.trim() : currentValue
      this.control.setValue(currentValue, { emitEvent: false })
    })
  }

  @HostListener('keydown.enter', ['$event'])
  onEnterKey(event: KeyboardEvent): void {
    if (!this.searchTerm) event.stopImmediatePropagation()
  }

  /**
   * Autofill job title
   * @param currentValue string
   * @returns 
   */
  private _autofillJobTitle(currentValue: string) {
    this.titleList.next([])
    const lastWord = currentValue.split(' ').pop().toLowerCase()
    this.searchTerm = currentValue.split(' ').pop()
    if (lastWord.length < 3) return
    if (lastWord === 'of' || lastWord === 'the') return
    let titleList = []

    titleList = titles.filter((title) => title.toLowerCase().includes(lastWord))
    if (titleList) this.titleList.next(titleList)
  }
}
