<mat-form-field appearance="outline">
  <mat-label [innerHtml]="control.field.label"></mat-label>
  <mat-chip-list [multiple]="allowMultipleValues" [errorStateMatcher]="hasErrors">
    <mat-chip
      *ngFor="let value of control.field.values"
      [value]="value.name"
      (click)="set(value.name)"
      (keydown.space)="toggle(value.name)"
      [selected]="is(value.name)"
    >
      {{ value.label }}
    </mat-chip>
  </mat-chip-list>
  <mat-hint>
    <mice-input-shortcuts></mice-input-shortcuts>
  </mat-hint>
  <mat-error>
    {{ errorMessage }}
  </mat-error>
</mat-form-field>
