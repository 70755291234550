import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { User } from '../models/user.interface'
import { environment } from './../../environments/environment'
import { tap } from 'rxjs/operators'
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public _version = environment.appVersion
  getLiveVersion(): Observable<any> {
    return this.http
      .get(`${environment.appUrl}/version?version=${environment.appVersion}&current=${Math.random().toString().replace("0.", "")}`)
      .pipe(tap((data) => (this._version = data['version'])))
  }

  get user(): User {
    if (!localStorage.getItem('crew-user')) {
      return null
    }
    return JSON.parse(localStorage.getItem('crew-user'))
  }

  set user(value: User) {
    localStorage.setItem('crew-user', JSON.stringify(value))
  }

  constructor(private http: HttpClient) {}

  login(email, password): Observable<any> {
    return this.http.post(`${environment.api}/v3/crew/users/login`, { email, password })
  }
  loginWithPlanningToken(token: string): Observable<any> {
    return this.http.get(`${environment.api}/v3/crew/users/login/planning-token`, {
      headers: {
        token: token,
        appId: '2'
      }
    })
  }
  loginWithToken(token: string): Observable<any> {
    return this.http.get(`${environment.api}/v3/crew/users`, {
      headers: {
        token: token,
        appId: '2'
      }
    })
  }
  setPassword(email: string, password: string, passwordConfirmation: string): Observable<any> {
    return this.http.post(`${environment.api}/v3/crew/users/set-password`, { email, password, password_confirmation: passwordConfirmation })
  }

  checkIfEmailRegistered(email: string) {
    return this.http.get(`${environment.api}/v3/crew/users/login-check?email=${email}`)
  }

  confirmEmail(formValue: any) {
    return this.http.post(`${environment.api}/v3/crew/users/resetPassword/email`, formValue)
  }

  resetPassword(formValue: any) {
    return this.http.post(`${environment.api}/v3/crew/users/resetPassword`, formValue)
  }
}
