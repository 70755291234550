export const geneva_international_motor_show_qatar_custom_badge = (settings, qrcode, fields: Object[] = null) => ({
    card: `
    <link href="https://db.onlinewebfonts.com/c/4150d76a852e1c349e0b6fe15f29d9ca?family=Brown+Bold" rel="stylesheet">
      <div class="badge-container">
        <div class="info-container">
        <div style="width:100%; height: 51mm;">
        <img style="width: 100%;border-radius: 10px 10px 0px 0px;" class="header" src="https://library.micetribe.com/images/GIMSQ_header_.png"
            alt="">
        </div>
        <div class="wrapper">
        ${fields
            ? fields.map((field: any) => {
                return field.enabled && (['fullname','organization'].includes(field.name)) ? `<div class="mb-2 ${field.name}">#_${field.name.toUpperCase()}_#</div>` : ''
            }).join('')
            : settings.fields.map((field: string) => {
                return settings[field] && settings[field].enabled ? `<div class="${field}">#_${field.toUpperCase()}_#</div>` : ''
            }).join('')
        }
        
        
        ${
          settings.qr.enabled
          ? `<div class="qr-code">${qrcode.innerHTML}</div> ${settings.qr.showText ? '<div class="qr-text">#_BARCODE_#</div>' : ''}`
          : ''
        }
        ${fields
            ? fields.map((field: any) => {
                return field.enabled && (field.name === 'company') ? `<div class="mb-2 ${field.name}">#_${field.name.toUpperCase()}_#</div>` : ''
            }).join('')
            : settings.fields.map((field: string) => {
                return settings[field] && settings[field].enabled ? `<div class="${field}">#_${field.toUpperCase()}_#</div>` : ''
            }).join('')
        }
        </div>
        <div class="#_PERSONA_#">
            ${settings.persona.enabled ? '<p class="persona  p-2-3 #_PERSONA_#">#_PERSONA_#</p>' : ''} 
        </div>
       
        <div style="width:100%;    height: 16mm;">
        <img style="width:100%;    border-radius: 0px 0px 10px 10px;" class="footer" src="https://library.micetribe.com/images/GIMSQ-footer.png"
            alt="">
        </div>
        
        </div>
      </div>
    `,
    style: `
      <style>
        * {
            font-family: "Brown Bold";
            border-radius: 0;
            margin: 0;
            padding: 0;
            font-weight: 400;
            visibility: visible;
            color: #000;
            text-align: center;
        }
  
        .mb-2 {
          margin-bottom: 2px !important;
        }
  
        .badge-container {
          width: 81mm !important;
          height: 120mm !important;
          margin-right: auto !important;
          margin-left: auto !important;
          text-align: center !important;
        }
      
        .info-container {
            display: flex !important;
            flex-direction: column !important;
            align-items: center !important;
            justify-content: center !important;
            padding-top: ${settings.topPadding}mm !important;
        }
      
        .persona {
          text-transform: uppercase !important;
          font-weight: 700 !important;
          font-size: ${settings.persona.fontSize}mm !important;
          line-height: ${settings.persona.fontSize}mm !important;
          margin: 0 !important;
          overflow: hidden !important;
          color: #000000 !important;
        }
      
        ${settings.fields.map((field: string) => {
        if (settings[field].enabled) {
            return `.${field} {
              padding-top: 2mm;
              font-size: ${settings[field].fontSize}mm !important;
              font-weight: 400 !important;
              overflow: hidden !important;
              color: #000000 !important;
              text-transform: uppercase;
              }`
        }
    }).join('')
        }
      
        .qr-text {
          text-transform: capitalize !important;
          font-size: ${settings.qr.fontSize}mm !important;
          line-height: ${settings.qr.lineHeight}mm !important;
          padding-bottom: ${settings.qr.bottomPadding}mm !important;
          margin: 0 !important;
          overflow: hidden !important;
          font-weight: 400 !important;
          color: #000000 !important;
        }
      
        .qr-code {
          width: ${settings.qr.width}mm !important;
        }
      
        .dtcm-text {
          text-transform: capitalize !important;
          font-size: ${settings.dtcm.fontSize}mm !important;
          line-height: ${settings.dtcm.lineHeight}mm !important;
          padding-bottom: ${settings.dtcm.bottomPadding}mm !important;
          margin: 0 !important;
          overflow: hidden !important;
          font-weight: 400 !important;
          color: #000000 !important;
        }
  
        .dtcm-code {
          padding-top: ${settings.dtcm.topPadding}mm !important;
          padding-bottom: ${settings.dtcm.bottomPadding}mm !important;
          height: ${settings.dtcm.height}mm !important;
        }
      
        .print-page {
          display: block
        }
      
        .page-break {
          display: block;
          page-break-before: always
        }
      
        @media print {
          .page-break {
            display: block;
            page-break-before: always
          }
        }
        .Exhibitor {
            background-color: #345a41 !important; 
            width: 100%;
        }
        .Media{
            background-color: #dedfda !important;
            width: 100%;
        }
        .Media p{
            color: #db3e38 !important;
            width: 100%;
        }
        .Organizer{
            background-color: #db3e38 !important;
            width: 100%;
        }
        .Sponsor{
            background-color: #6f6f6c !important;
            width: 100%;
        }
        .Partner{
            background-color: #9e7c4a !important;
            width: 100%;
        }
        .Services{
            background-color: #4d89b6 !important;
            width: 100%;
        }
        .Security{
            background-color: #6c2936 !important;
            width: 100%;
        }
        .Vip{
            background-color: #0a0a0a !important;
            width: 100%;
        }
        .Moi{
            background-color: #67091F !important;
            width: 100%;
        }
        .Protocol{
            background-color: #AE9252 !important;
            width: 100%;
        }

         p {
            color: #fffffe !important;
            margin: 0 !important
        }
        .company{
          font-size: 5mm;
          text-transform: uppercase;
        }
        .p-2-3{
            padding: 1mm 0mm;
        }
        .persona{
            color: #fffffe !important;
        }
        .wrapper{
          height: 40.5mm;
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex-direction: column;
        }
      </style>
    `
})
