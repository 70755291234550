import { Component, OnInit } from '@angular/core'
import { Validators } from '@angular/forms'
import { ControlAttribute } from '../../attributes/control.attribute'

@Component({
  selector: 'cf-url',
  templateUrl: './url.component.html',
  styleUrls: ['./url.component.scss']
})
export class UrlComponent extends ControlAttribute {
  constructor() {
    super()
  }
}
