import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PrintComponent } from './components/print/print.component'
import { DtcmPrintWaitComponent } from './components/dtcm-print-wait/dtcm-print-wait.component'
import { HttpClientModule } from '@angular/common/http'
import { QRCodeModule } from 'angularx-qrcode'
import { FormsModule } from '@angular/forms'
import { PrintService } from './services/print.service';
import { BadgeComponent } from './components/badge/badge.component'
@NgModule({
  declarations: [PrintComponent, DtcmPrintWaitComponent, BadgeComponent],
  imports: [CommonModule, HttpClientModule, QRCodeModule, FormsModule],
  exports: [PrintComponent, DtcmPrintWaitComponent],
  providers: [PrintService]
})
export class PrintingModule {}
