import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ControlAttribute } from '../../attributes/control.attribute';

@Component({
  selector: 'cf-identity',
  templateUrl: './identity.component.html',
  styleUrls: ['./identity.component.scss']
})
export class IdentityComponent extends ControlAttribute implements OnInit {
  private _destroy$ = new Subject()

  constructor() {
    super()
  }
  ngOnInit(): void {
    this.control.setValidators([Validators.minLength(4)])
    this.control.updateValueAndValidity();
    this.control.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((currentValue: string) => {
      currentValue = currentValue.replace(/[^\w\s]/g, "").trim();
      this.control.setValue(currentValue, { emitEvent: false })
    })
  }
}
