import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ControlAttribute } from '../../attributes/control.attribute'
import { RepositoriesService } from '../../services/repositories.service'
import { AsYouType } from 'libphonenumber-js'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
@Component({
  selector: 'cf-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent extends ControlAttribute implements OnInit, OnDestroy {
  asYouType = new AsYouType()
  @ViewChild('htmlField')
  htmlField: ElementRef
  private _destroy$ = new Subject()
  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }
  constructor(private repoService: RepositoriesService, private cdr: ChangeDetectorRef) {
    super()
  }

  ngOnInit(): void {
    this.asYouType.reset()
    const formatted = this.asYouType.input(this.control.value)
    this.control.setValue(formatted && formatted.includes('+') ? formatted : '+' + formatted, { emitEvent: true })
    this.cdr.detectChanges()
    this.control.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((currentValue: string) => {
      if (!currentValue) {
        this.control.setValue('+', { emitEvent: true })
        return
      };
      this.asYouType.reset()
      const formatted = this.asYouType.input(currentValue)
      this.control.setValue(formatted && formatted.includes('+') ? formatted : '+' + formatted, { emitEvent: false })
      this.cdr.detectChanges()
    })
  }
  onKeyPress(e) {
    const charCode = e.which ? e.which : e.keyCode
    if (!this.isPhoneInput(e)) {
      e.preventDefault()
    }
    if (e.target.value === '+' && charCode === 8) {
      e.preventDefault()
    }
    if (e.target.value && e.target.value.includes('+') && charCode === 43) {
      e.preventDefault()
    }
  }
  isPhoneInput(e) {
    const charCode = e.which ? e.which : e.keyCode
    if (charCode !== 9 && charCode !== 43 && charCode !== 8 && (charCode < 48 || charCode > 57)) {
      return false
    }
    return true
  }
  handleFocus() {
    this.htmlField.nativeElement.selectionStart = this.htmlField.nativeElement.selectionEnd = this.htmlField.nativeElement.value.length
  }
  get defaultCountry() {
    return this.repoService.countries.find((c) => c.name === this.control.field.defaultCountry)
  }
  get defaultFlag() {
    return this.defaultCountry ? this.defaultCountry.flag : ''
  }

  get flag() {
    if (!this.asYouType.country) {
      return 'assets/images/none.svg'
    }
    const country = this.repoService.countries.find((c) => c.iso2 === this.asYouType.country)
    if (country) {
      return country.flag
    }
    return this.defaultFlag
  }
  get flagCountry() {
    if (!this.asYouType.country) {
      return ''
    }
    const country = this.repoService.countries.find((c) => c.iso2 === this.asYouType.country)
    if (country) {
      return country.name
    }
    return ''
  }
}
