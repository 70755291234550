import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '@environments/environment'
import { AuthService } from '@app/services/auth.service'
import { Router } from '@angular/router'
import { tap } from 'rxjs/operators'
import { RegistrationDocument } from '@app/modules/@micetribe/forms/models/registration-document.model'
import { SearchResult } from '@app/modules/@micetribe/search/models/search.result.model'
import { Event } from '@app/modules/event/interfaces/event-item.interface'
import { IProduct } from '@app/models/product.model'
@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  crewActiveEventChanged$ = new BehaviorSubject<{}>(
    localStorage.getItem('crew-active-event') ? JSON.parse(localStorage.getItem('crew-active-event')) : {}
  )
  public updateRegisteration = new Subject()
  private _eventProducts: IProduct[]
  public activeEventChanged: BehaviorSubject<Event> = new BehaviorSubject<Event>(this.activeEvent)
  public printDocument$: BehaviorSubject<RegistrationDocument> = new BehaviorSubject<RegistrationDocument>(null)
  public printSearchResult$: BehaviorSubject<{ result: SearchResult; self: boolean }> = new BehaviorSubject<{
    result: SearchResult
    self: boolean
  }>(null)
  public _activeEventPersonas = []
  public activeEventPersonasSet = false

  public set activeEventPersonas(value: any[]) {
    this._activeEventPersonas = value
  }
  public get activeEventPersonas() {
    if (!this._activeEventPersonas.length && !this.activeEventPersonasSet) {
      this._activeEventPersonas = Object.keys(this.activeEvent.eventDetails?.forms).map((key) => {
        return {
          name: this.activeEvent.eventDetails?.forms[key].name,
          slug: this.activeEvent.eventDetails?.forms[key].slug
        }
      })
    }
    return this._activeEventPersonas
  }

  public set activeEvent(event: Event) {
    if (event?.eventDetails.id !== this.activeEvent?.eventDetails.id) {
      localStorage.removeItem('crew-tabs')
      /**
       * @TODO remove event products item 'crew-event-products'
       */
      localStorage.removeItem('crew-event-products')
    }
    /**
     * @TODO fetch event products and save it to localstorage
     */
    localStorage.setItem('crew-active-event', JSON.stringify(event))
    this.fetchProductsPerEvent(event.event_id)
    this.crewActiveEventChanged$.next(event)
    this.activeEventChanged.next(event)
    this.activeEventPersonas = Object.keys(event.eventDetails.forms).map((key) => {
      return {
        name: event.eventDetails.forms[key].name,
        slug: event.eventDetails.forms[key].slug
      }
    })
  }

  public get activeEvent() {
    const event: Event = JSON.parse(localStorage.getItem('crew-active-event'))
    if (!event && !!this.auth.user) {
      this.router.navigate(['home', 'events'])
      return
    }
    return event
  }
  constructor(private http: HttpClient, private auth: AuthService, private router: Router) {}

  get workspaceSlug() {
    return this.activeEvent?.eventDetails?.workspace?.slug
  }
  get eventSlug() {
    return this.activeEvent?.eventDetails?.slug
  }
  get eventId() {
    return this.activeEvent?.eventDetails?.id
  }

  get eventProducts() {
    return this._eventProducts ? this.eventProducts : JSON.parse(localStorage.getItem('crew-event-products'))
  }

  updatePersona(desc, formId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      token: this.auth.user.token,
      appId: '2'
    })
    return this.http
      .patch(
        // `${environment.api}/api/v1/external/workspaces/${workspaceSlug}/events/${eventSlug}/forms/${formSlug}/description`,
        `${environment.api}/v3/plan/contactless/forms`,
        {
          id: formId,
          public_description: desc
        },
        { headers: headers }
      )
      .pipe(
        tap(() => {
          // const activeEvent:EventData = this.activeEvent
          // const forms = activeEvent.eventDetalis.forms.find(o => o.slug == formSlug)
          // currentActiveTab.description = desc
          // this.currentTabs = currentTabs
        })
      )
  }

  refreshEvent(event) {
    if (this.activeEvent) {
      // localStorage.removeItem('crew-tabs')
      this.fetchProductsPerEvent(event.event_id)
      localStorage.setItem('crew-active-event', JSON.stringify(event))
      this.crewActiveEventChanged$.next(event)
      this.activeEventChanged.next(event)
      this.activeEventPersonas = Object.keys(event.eventDetails.forms).map((key) => {
        return {
          name: event.eventDetails.forms[key].name,
          slug: event.eventDetails.forms[key].slug
        }
      })
    }
  }

  fetchProductsPerEvent(eventId: number = null) {
    if (!eventId) return
    this.http
      .get<IProduct[]>(`${environment.api}/v3/registrations/product/${this.workspaceSlug}/${this.eventSlug}`, {
        headers: new HttpHeaders({
          appId: '2',
          token: this.auth.user.token
        }),
        params: {
          event_id: eventId.toString()
        }
      })
      .subscribe((res) => {
        localStorage.setItem('crew-event-products', JSON.stringify(res))
      })
  }
}
