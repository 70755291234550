export const customer_experience_live_sponsor = (settings, qrcode, fields: Object[] = null) => ({
    card: `
    <link href="https://db.onlinewebfonts.com/c/4150d76a852e1c349e0b6fe15f29d9ca?family=Brown+Bold" rel="stylesheet">
    <div class="badge-container">
    <div class="info-container">
        <div class="wrapper">
            <div id="organizationWrapper">
            <p id="organization" class="organization">#_ORGANIZATION_#</p>
            </div>
            <div id="fullnameWrapper">
            <p id="fullnameText" class="fullname">#_FULLNAME_#</p>
            </div>
            <div id="jobTitleWrapper">
            <p  id="jobTitle" class="job_title">#_JOB_TITLE_#</p>
            </div>
            <p class="organizer">#_PERSONA_#</p>
            <div class="qr-code">
                <div class="qrcode">
                ${`<div class="qr-code">${qrcode.innerHTML}</div>`}
                </div>
            </div>
            <div class="automation_show">#_INPUT3_#</div>
        </div>
        <div class="meetings_sections">
            <div class="dtcm-code">
                #_DTCM_BARCODE_IMAGE_#
            </div>
        </div>

    </div>
</div>
<script>
                function checkOverflow() {
                    const div = document.getElementById('meetings');
                    const text = document.getElementById('meetings_text');

                    const fullnameWrapper = document.getElementById('fullnameWrapper');
                    const fullnameText = document.getElementById('fullnameText');

                    const jobTitleWrapper = document.getElementById('jobTitleWrapper');
                    const jobTitle = document.getElementById('jobTitle');

                    const organizationWrapper = document.getElementById('organizationWrapper');
                    const organization = document.getElementById('organization');

                    if (text.offsetHeight > div.offsetHeight || text.offsetWidth > div.offsetWidth) {
                        let fontSize = parseInt(window.getComputedStyle(text).fontSize);
                        while (text.offsetHeight > div.offsetHeight || text.offsetWidth > div.offsetWidth) {
                            fontSize -= 1;
                            text.style.fontSize = fontSize + 'px';
                        }
                    }

                    if (fullnameText.offsetHeight > fullnameWrapper.offsetHeight || fullnameText.offsetWidth > fullnameWrapper.offsetWidth) {
                        let fontSize = parseInt(window.getComputedStyle(fullnameText).fontSize);
                        while (fullnameText.offsetHeight > fullnameWrapper.offsetHeight || fullnameText.offsetWidth > fullnameWrapper.offsetWidth) {
                            fontSize -= 1;
                            fullnameText.style.fontSize = fontSize + 'px';
                        }
                    }

                    if (jobTitle.offsetHeight > jobTitleWrapper.offsetHeight || jobTitle.offsetWidth > jobTitleWrapper.offsetWidth) {
                        let fontSize = parseInt(window.getComputedStyle(jobTitle).fontSize);
                        while (jobTitle.offsetHeight > jobTitleWrapper.offsetHeight || jobTitle.offsetWidth > jobTitleWrapper.offsetWidth) {
                            fontSize -= 1;
                            jobTitle.style.fontSize = fontSize + 'px';
                        }
                    }

                    if (organization.offsetHeight > organizationWrapper.offsetHeight || organization.offsetWidth > organizationWrapper.offsetWidth) {
                        let fontSize = parseInt(window.getComputedStyle(organization).fontSize);
                        while (organization.offsetHeight > organizationWrapper.offsetHeight || organization.offsetWidth > organizationWrapper.offsetWidth) {
                            fontSize -= 1;
                            organization.style.fontSize = fontSize + 'px';
                        }
                    }
                }
                window.addEventListener('load', checkOverflow);
                window.addEventListener('resize', checkOverflow);
                </script>
    `,
    style: `
      <style>
      * {
      font-family: "Brown Bold";
      border-radius: 0;
      margin: 0;
      padding: 0;
      font-weight: 400;
      visibility: visible;
      color: #000;
  }
  
  .wrapper {
      text-align: center;
      padding: 0 2mm;
      height: 52mm;
    }
  
  .mb-2 {
      margin-bottom: 2px !important;
  }
  
  .badge-container {
      width: 81mm !important;
      height: 126mm !important;
      margin-right: auto !important;
      margin-left: auto !important;
      text-align: center !important;
      transform: rotateZ(180deg) !important;
  }
  
  .info-container {
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
      justify-content: center !important;
      padding-top: 47mm !important;
  }
  
  
  
  .fullname {
      padding-top: 2mm;
      font-size: 4.5mm;
      font-weight: 400 !important;
      overflow: hidden !important;
      color: #000000 !important;
      text-transform: uppercase;
  }

  #fullnameWrapper{
    max-height: 7mm;
  }
  
  .organization {
      padding-top: 2mm;
      font-size: 4.5mm;
      font-weight: 400 !important;
      overflow: hidden !important;
      color: #000000 !important;
      text-transform: uppercase;
  }

  #organizationWrapper{
    max-height: 7mm;
  }

  .job_title {
    font-size: 2.5mm;
    font-weight: 400 !important;
    overflow: hidden !important;
    color: #000000 !important;
    text-transform: uppercase;
}

#jobTitleWrapper{
    max-height: 5mm;
}

  
  .organizer {
      padding-top: 3mm;
      font-size: 4.5mm !important;
      font-weight: 400 !important;
      overflow: hidden !important;
      color: #000000 !important;
      text-transform: uppercase;
  }
  
  .automation_show {
      padding-top: 2mm;
      font-size: 2.7mm !important;
      font-weight: 400 !important;
      overflow: hidden !important;
      color: #000000 !important;
      text-transform: uppercase;
  }
  
  
  
  .qr-text {
      text-transform: uppercase !important;
      margin: 0 !important;
      overflow: hidden !important;
      font-weight: 400 !important;
      color: #000000 !important;
  }
  
  .qr-code {
      width: 18mm !important;
      margin: auto;
  }
  
  .dtcm-code {
    padding-top: 5mm !important;
    margin: auto;
    text-align: center;
    position: absolute;
    bottom: -40px;
    left: calc(50% - 6.5px);
  }
  
  .dtcm-code img{
    width: 25px;
    text-align: center;
    margin: auto;
  }
  
  .print-page {
      display: block
  }
  
  .page-break {
      display: block;
      page-break-before: always
  }
  
  @media print {
      .page-break {
          display: block;
          page-break-before: always
      }
  }
  
  .meetings{
    height: 4mm;
  }
  .meetings_sections {
      text-align: left;
      width: 70%;
      padding: 0 10mm;
      padding-top: 3mm;
      position: relative;
  }
  
  .lc-lr {
      display: flex;
      gap: 10mm;
  }
  
  .meetings_sections .lc p,
  .meetings_sections .lr p,
  .meetings_sections .meetings p {
      font-size: 2.6mm;
      color: black;
  }
   
      </style>
    `
})
