import { Component, OnInit } from '@angular/core';
import { ControlAttribute } from '../../attributes/control.attribute';

@Component({
  selector: 'cf-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent extends ControlAttribute implements OnInit {
  constructor() {
    super()
  }

  ngOnInit(): void {}
}
