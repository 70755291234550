<mat-form-field appearance="outline">
  <mat-label [innerHtml]="control.field.label"></mat-label>
  <input matInput type="text" [formControl]="control" autocomplete="off" />

  <mat-hint>
    <mice-input-shortcuts></mice-input-shortcuts>
  </mat-hint>
  <mat-error>
    {{ errorMessage }}
  </mat-error>
</mat-form-field>
