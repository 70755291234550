import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormsService } from '../../services/forms.service';
import { ViewChild } from '@angular/core'
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'
import { Observable, Subject } from 'rxjs'
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators'

import { ControlAttribute } from '../../attributes/control.attribute'

@Component({
  selector: 'cf-city-field',
  templateUrl: './city-field.component.html',
  styleUrls: ['./city-field.component.scss']
})
export class CityFieldComponent extends ControlAttribute implements OnInit {
  autocompleteOptions: string[] = []
  loading = false
  cities = new Subject<string[]>()
  constructor(private formsService: FormsService) {
    super()
  }

  private _destroy$ = new Subject()

  public resource: Observable<string[]>

  @ViewChild('country', { read: MatAutocompleteTrigger }) autoTrigger: MatAutocompleteTrigger

  ngOnInit(): void {
    const value = this.control.value
    this.control.patchValue(value ? `${value['formatted_address']}` : '')
    this.control.valueChanges.pipe(takeUntil(this._destroy$)).subscribe({
      next: (res) => {
        this.loading = true;
        this.formsService.searchCity(res).pipe(debounceTime(500), takeUntil(this._destroy$)).subscribe({
          next: (res: any) => {
            this.loading = false;
            this.cities.next(res.features)
            this.resource = this.cities
          }
        })
      }
    })
  }
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }
  ngAfterViewInit(): void {
    this.autoTrigger.panelClosingActions.subscribe(() => {
      if (this.autoTrigger.activeOption) {
        this.control.setValue(this.autoTrigger.activeOption.value)
        this.resource = null
        this.resource = this.control.valueChanges.pipe(
          takeUntil(this._destroy$),
          startWith(''),
          map((value) => (value))
        )
      }
    })
  }

  onEnter(event: KeyboardEvent): void {
    event.preventDefault()
    event.stopPropagation()
  }
}
