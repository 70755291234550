import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(private http: HttpClient) {}

  uploadFile(formData: FormData): Observable<any> {
    return this.http.post(`${environment.api}/v3/library/public`, formData, {
      reportProgress: true,
      observe: 'events'
    })
  }

  rotateFile(payload: {
    workspace: string
    event: string
    form: string
    element: string
    registration_id: string
    angle: number
  }): Observable<any> {
    return this.http.patch(`${environment.api}/v3/library/public/rotate`, payload)
  }
}

