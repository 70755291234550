import { AbstractControl } from '@angular/forms'
import { AbstractValidator } from './abstract.validator'
import { ValidationTypes } from './validation.types'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'

export class RequiredPhoneValidator implements AbstractValidator {
  static type = ValidationTypes.validateWith

  static validateWith({ defaultCountry }: { defaultCountry: any }) {
    return (control: AbstractControl) => {
      const defaultCode = defaultCountry ? '+' + defaultCountry.e164 : '+'
      return !(control.value === defaultCode || control.value === '+')
    }
  }
}
