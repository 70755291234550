<mat-form-field appearance="outline">
    <mat-label [innerHtml]="control.field.label"></mat-label>
    <input matInput type="text" [formControl]="control"/>
  
    <mat-hint>
      <mice-input-shortcuts></mice-input-shortcuts>
    </mat-hint>
    <mat-error *ngIf="control.touched && control.errors">
        The ID number you provided is too short
    </mat-error>
  </mat-form-field>
  