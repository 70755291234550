import { AbstractControl } from '@angular/forms'
import { AbstractValidator } from './abstract.validator'
import { ValidationTypes } from './validation.types'

export class EmailValidator implements AbstractValidator {
  static regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  static type = ValidationTypes.validate
  static validate(control: AbstractControl) {
    if (!control.value) {
      return null
    }
    return EmailValidator.regExp.test(control.value) ? null : { email: `Invalid email address` }
  }
}
