import { Component } from '@angular/core'
import { ControlAttribute } from '../../attributes/control.attribute'

@Component({
  selector: 'cf-information-message',
  templateUrl: './information-message.component.html',
  styleUrls: ['./information-message.component.scss']
})
export class InformationMessageComponent extends ControlAttribute {
  constructor() {
    super()
  }
}
