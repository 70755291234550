import { AbstractControl } from '@angular/forms'
import { AbstractValidator } from './abstract.validator'
import { ValidationTypes } from './validation.types'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'

export class PhoneValidator implements AbstractValidator {
  static type = ValidationTypes.validateWith

  static validateWith({ defaultCountry }: { defaultCountry: any }) {
    return (control: AbstractControl) => {
      if(!control.value) return;
      const parsedNumber = parsePhoneNumberFromString(control.value)
      if ((control.field?.isRequired < 2 && control.pristine)) {
        return null
      }
      const defaultCode = parsedNumber ? '+' +  parsedNumber.countryCallingCode + ' 0' : '+0'
      if (control.value.startsWith(defaultCode)) {
        return { phone: 'Phone can not start with a leading zero after country code' }
      }
      try {
        const validity = parsedNumber ? parsedNumber.isValid() : false
        return validity ? null : { phone: `Invalid phone number` }
      } catch {
        return { phone: `Is an invalid phone number` }
      }
    }
  }
}
