export const wise2023 = (settings, qrcode, fields: Object[] = null) => ({
  card: `
            <div class="badge-container">
            <div class="info-container">

                <div class="wise-header-wrapper">
                   <img class="wise-header" src="https://library.micetribe.com/images/wise_header.png" alt="">
                </div>

                <div class="profile_qr">
                    <div class="wrapper-profile-qr">
                        <div class="profile_pic">
                            <img class="user-pic" src="#_PHOTO_#" alt="">
                        </div>
                        ${`<div class="qr-code">${qrcode.innerHTML}</div>`}
                    </div>
                </div>

                <div class="fullname_section">
                    <p class="arabic_text arabic_name">#_FULLNAME_AR_#</p>
                    <p class="english_text uppercase">#_FULLNAME_#</p>
                </div>


                <div class="org_section">
                    <p class="english_text">#_ORGANIZATION_#</p>
                </div>

                <div class="category #_CATEGORYBACKGROUND_#">
                    <p class="cat_arabic">#_AR_BADGE_TYPE_#</p>
                    <p class="cat_english">#_BADGE_TYPE_#</p>
                </div>
            </div>
        </div>
        <script>
        const urls = [
            'https://library.micetribe.com/fonts/DIN Next LT Arabic Bold.woff',
            'https://library.micetribe.com/fonts/D-DIN.ttf',
            'https://library.micetribe.com/images/wise_header.png'
        ];
        const fetchPromises = urls.map(url => fetch(url));
        Promise.all(fetchPromises)
        .then(dataArray => {
            setTimeout(() => {
                window.print()
            }, 500)
        })
        .catch(error => {
            window.print()
        });
        </script>
    `,
  style: `
  <style>
  * {
  font-family: 'D-DIN', sans-serif;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-weight: 400;
  visibility: visible;
  color: #000;
  text-align: center;
    }

    .mb-2 {
      margin-bottom: 2px !important;
    }

    .badge-container {
      width: 90mm !important;
      height: 139.6mm !important;
      margin-right: auto !important;
      margin-left: auto !important;
      text-align: center !important;
      justify-content: center !important;
      transform: rotateZ(180deg) !important;
    }

    .info-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 0 4mm;
    }

    .print-page {
      display: block;
    }

    .page-break {
      display: block;
      page-break-before: always;
    }

    .wise-header {
      width: 82%;
    }

    .profile_qr {
      display: flex;
      padding: 0 0mm;
      justify-content: center;
      width: 100%;
      overflow: hidden;
      align-items: center;
    }

    .wrapper-profile-qr {
      display: flex;
      justify-content: space-between;
      width: 81%;
      overflow: hidden;
    }

    .user-pic {
      height: 31mm;
      padding: 12px 0;
      max-width: 31mm;
      object-fit: cover;
    }

    .qr {
      width: 30mm;
      height: 30mm;
      padding-top: 3mm;
    }

    .qr-code {
      width: 31mm !important;
    }

    .english_text {
      font-family: 'D-DIN', sans-serif;
    }

    .arabic_text {
      font-family: 'DIN Next LT Arabic';
    }

    .arabic_name {
      padding-bottom: 1mm;
    }

    .arabic_org {
      /* padding-bottom: 2mm; */
    }

    .fullname_section {
      height: 27mm;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 98%;
    }

    .fullname_section p {
      font-size: 6.2mm;
    }

    .org_section {
      padding: 2mm 0;
    }

    .org_section p {
      font-size: 5.2mm;
    }

    .cat_arabic {
      font-family: 'DIN Next LT Arabic';
      font-weight: bold;
      direction: rtl;
    }

    .category {
      margin-bottom: 4mm;
      height: 19mm;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 2mm 0;
    }

    .category p {
      color: white;
      font-size: 7.7mm;
      text-transform: uppercase;
      line-height: 12mm;
    }

    .media_color {
      background-color: #7349bc;
    }

    .eeaspeaker_color {
      background-color: #474b4d;
    }

    .eeaspeaker_color p.cat_arabic {
      font-size: 5mm !important;
    }

    .qf_color {
      background-color: #004438;
    }

    .wise_team_color {
      background-color: #004F5F;
    }

    .speaker_color {
      background-color: #00a19b;
    }

    .wise-header-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15mm;
    }

    @font-face {
      font-family: 'DIN Next LT Arabic';
      src: url('https://library.micetribe.com/fonts/DIN Next LT Arabic Bold.woff') format('woff');
    }

    @font-face {
      font-family: 'D-DIN';
      src: url('https://library.micetribe.com/fonts/D-DIN.ttf') format('truetype');
    }

    @media print {
      .page-break {
        display: block;
        page-break-before: always;
      }
    }
  </style>
    `
})

