<div  class="info">
  <span class="icon">
    <svg width="30" height="30" viewBox="0 0 24 24" role="presentation">
      <path
        d="M12 20a8 8 0 110-16 8 8 0 010 16zm0-8.5a1 1 0 00-1 1V15a1 1 0 002 0v-2.5a1 1 0 00-1-1zm0-1.125a1.375 1.375 0 100-2.75 1.375 1.375 0 000 2.75z"
        fill="currentColor"
        fill-rule="evenodd"
      ></path>
    </svg>
  </span>
  <span data-toggle="tooltip" data-placement="top" [title]="control.field.label" class="message">{{ control.field.label }}</span>
</div>
