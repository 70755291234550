import { AfterViewInit, Component, OnDestroy } from '@angular/core'
import { AuthService } from './services/auth.service'
import { environment } from '../environments/environment'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { NewVersionComponent } from './modules/auth/components/new-version/new-version.component'
import { interval, Subject } from 'rxjs'
import { filter, startWith, takeUntil } from 'rxjs/operators'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {
  private _destroy$ = new Subject()
  private _modalRef: NgbModalRef
  public printing = false

  constructor(private authService: AuthService, private ngbModal: NgbModal) {}
  ngAfterViewInit(): void {
    if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
      return
    }
    interval(60000)
      .pipe(
        takeUntil(this._destroy$),
        startWith(0),
        filter(() => !this._modalRef?.componentInstance)
      )
      .subscribe(() => {
        this.authService
          .getLiveVersion()
          .pipe(takeUntil(this._destroy$))
          .subscribe((data) => {
            if (data['version'] !== environment.appVersion) {
              this._modalRef = this.ngbModal.open(NewVersionComponent, { backdrop: true, windowClass: 'modal-padless' })
            }
          })
      })
  }
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }
}
