import { AbstractControl } from '@angular/forms'
import { AbstractValidator } from './abstract.validator'
import { ValidationTypes } from './validation.types'

export class UrlValidator implements AbstractValidator {
  static regExp = /^(https?:\/\/)?(www\.)?([^www][a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}\/?([a-zA-Z0-9#?&\-+_%=]+\/?)*$/i
  static type = ValidationTypes.validate
  static validate(control: AbstractControl) {
    if (!control.value) {
      return null
    }
    return UrlValidator.regExp.test(control.value) ? null : { url: `Invalid URL format` }
  }
}
