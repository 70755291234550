<div class="warn">
  <span class="icon">
    <svg width="30" height="30" viewBox="0 0 24 24" role="presentation">
      <g fill-rule="evenodd">
        <path
          d="M12.938 4.967c-.518-.978-1.36-.974-1.876 0L3.938 18.425c-.518.978-.045 1.771 1.057 1.771h14.01c1.102 0 1.573-.797 1.057-1.771L12.938 4.967z"
          fill="currentColor"
        ></path>
        <path d="M12 15a1 1 0 01-1-1V9a1 1 0 012 0v5a1 1 0 01-1 1m0 3a1 1 0 010-2 1 1 0 010 2" fill="#fffae6"></path>
      </g>
    </svg>
  </span>
  <span>{{ control.field.label }}</span>
</div>
