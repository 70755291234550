import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { MatChipList } from '@angular/material/chips'
import { Subject } from 'rxjs'
import { take, takeUntil } from 'rxjs/operators'
import { ControlAttribute } from '../../attributes/control.attribute'
import { FormsService } from '../../services/forms.service'

@Component({
  selector: 'cf-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent extends ControlAttribute implements OnInit, OnDestroy, AfterViewInit {
  private _destroy$: Subject<void> = new Subject()
  @ViewChild(MatChipList) chips: MatChipList

  constructor(private formsService: FormsService, private cdr: ChangeDetectorRef) {
    super()
  }

  ngOnInit(): void {
    this.control.statusChanges.pipe(takeUntil(this._destroy$)).subscribe((status) => {
      this.chips.errorState = status === 'INVALID'
    })
  }
  ngAfterViewInit() {
    this.chips.chipFocusChanges.pipe(takeUntil(this._destroy$), take(1)).subscribe((res) => {
      this.control.setValue(this.control.value)
      if (this.chips.focused) {
        this.control.markAsTouched()
      }
    })

    this.formsService
      .getGender()
      .pipe(takeUntil(this._destroy$))
      .subscribe((gender) => {
        if (this.formsService.hasGender) {
          const value = this.control.field.values.find((v) => v.name.toLowerCase() === gender)
          if (value) {
            this.set(gender)
            this.cdr.detectChanges()
          }
        }
      })
  }
  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }
}

