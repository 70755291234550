import { settings } from './settings'
import { agencepxPowerofinnovation } from './agencepx-powerofinnovation';
import { defaultBadge } from './default-badge';
import { qatarFashionUnited } from './qatar-fashion-united';
import { tikitaka } from './tikitaka';
import { geneva_international_motor_show_qatar } from './geneva_international_motor_show_qatar';
import { udstItas } from './udst-itas';
import { wise2021 } from './wise2021';
import { wise2021Ted } from './wise2021-ted';
import { geneva_international_motor_show_qatar_custom_badge } from './geneva_international_motor_show_qatar_custom_badge';
import { wise2023 } from './wise2023';
import { katara_wahc23 } from './katara-wahc23';
import { qga_cbqm27 } from './qga_cbqm27';
import { customer_experience_live } from './customer_experience_live';
import { customer_experience_live_sponsor } from './customer_experience_live_sponsor';
import { via_sct24 } from './via_xct24';

const mapFile = new Map()
mapFile.set('agencepx-powerofinnovation', agencepxPowerofinnovation)
mapFile.set('default-badge', defaultBadge)
mapFile.set('qatar-fashion-united', qatarFashionUnited)
mapFile.set('tikitaka', tikitaka)
mapFile.set('geneva_international_motor_show_qatar', geneva_international_motor_show_qatar)
mapFile.set('geneva_international_motor_show_qatar_custom_badge', geneva_international_motor_show_qatar_custom_badge)
mapFile.set('udst-itas', udstItas)
mapFile.set('wise2021', wise2021)
mapFile.set('wise2021-ted', wise2021Ted)
mapFile.set('wise2023', wise2023)
mapFile.set('katara-wahc23', katara_wahc23)
mapFile.set('qga_cbqm27', qga_cbqm27)
mapFile.set('customer_experience_live', customer_experience_live)
mapFile.set('customer_experience_live_sponsor', customer_experience_live_sponsor)
mapFile.set('via_sct24', via_sct24)

export function getBadgeTemplate(template: string, qrcode: any, fields = null) {
    const badge = mapFile.get(template)
  return badge(settings, qrcode, fields)
}