import { Component, OnInit } from '@angular/core';
import { ControlAttribute } from '../../attributes/control.attribute';

@Component({
  selector: 'cf-plain-text',
  templateUrl: './plain-text.component.html',
  styleUrls: ['./plain-text.component.scss']
})
export class PlainTextComponent extends ControlAttribute implements OnInit {
  constructor() {
    super()
  }

  ngOnInit(): void {}
}
