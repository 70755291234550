export const settings = {
  font: 'Roboto',
  width: 82,
  height: 127,
  topPadding: 52,
  persona: {
    enabled: true,
    fontSize: 10,
    lineHeight: 10,
    bottomPadding: 2,
  },
  qr: {
    enabled: true,
    showText: true,
    fontSize: 2.5,
    lineHeight: 2.5,
    topPadding: 1,
    bottomPadding: 1,
    width: 18,
  },
  dtcm: {
    enabled: false,
    showText: false,
    fontSize: 2.5,
    lineHeight: 2.5,
    topPadding: 1,
    bottomPadding: 1,
    height: 5,
  },
  fields: []
}