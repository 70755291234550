import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable, Type } from '@angular/core'
import {
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
  AbstractControl,
  FormArray,
  ValidationErrors
} from '@angular/forms'
import { environment } from '@environments/environment'
import { BehaviorSubject, Observable, of, Subject } from 'rxjs'
import { map } from 'rxjs/operators'
import { ControlAttribute } from '../attributes/control.attribute'
import { CountryComponent } from '../components/country/country.component'
import { DateComponent } from '../components/date/date.component'
import { EmailComponent } from '../components/email/email.component'
import { FullnameComponent } from '../components/fullname/fullname.component'
import { JobTitleComponent } from '../components/job-title/job-title.component'
import { NumericComponent } from '../components/numeric/numeric.component'
import { OrganizationComponent } from '../components/organization/organization.component'
import { PhoneComponent } from '../components/phone/phone.component'
import { PlainTextComponent } from '../components/plain-text/plain-text.component'
import { RadioComponent } from '../components/radio/radio.component'
import { UploadComponent } from '../components/upload/upload.component'
import { UrlComponent } from '../components/url/url.component'
import { BreakLineComponent } from '../components/break-line/break-line.component'
import { InformationMessageComponent } from '../components/information-message/information-message.component'
import { WarningMessageComponent } from '../components/warning-message/warning-message.component'
import { Field, Fields } from '../models/field.model'

import { MiceForm } from '../models/form.model'
import { salutations, combinedNouns } from '../data/fullname-data'
import { startCase } from 'lodash'

import { MinWordsValidator } from '../validators/min-words.validator'
import { EmailValidator } from '../validators/email.validator'
import { UrlValidator } from '../validators/url.validator'
import { PhoneValidator } from '../validators/phone.validator'
import { RequiredPhoneValidator } from '../validators/required-phone.validator'
import { SelectionValidator } from '../validators/selection.validator'
import { RegistrationDocument } from '../models/registration-document.model'
import { HeaderService } from '@app/modules/home/services/header.service'
import { RepositoriesService } from './repositories.service'
import { AuthService } from '@app/services/auth.service'
import { Settings } from '../models/settings.model'
import { StarRatingComponent } from '../components/star-rating/star-rating.component'
import { IdentityComponent } from '../components/identity/identity.component'
import { AutocompeleteComponent } from '../components/autocompelete/autocompelete.component'
import { CityFieldComponent } from '../components/city-field/city-field.component'
@Injectable({
  providedIn: 'root'
})
export class FormsService {
  hasGender: boolean = false
  private _gender$ = new BehaviorSubject<string>(null)
  constructor(
    private http: HttpClient,
    private headerService: HeaderService,
    private repoService: RepositoriesService,
    private auth: AuthService
  ) { }

  /**
   * @TODO activeGD value should be changed depened on the api call enable MRZ or disable it
   */
  private _enableGenderDetection = true

  get activeGenderDetection() {
    return this._enableGenderDetection
  }

  set activeGenderDetection(activate) {
    this._enableGenderDetection = activate
  }

  getDefaultValues(formGroup: FormGroup): { [key: string]: string } {
    const supportedFields: Field[] = []
    Object.keys(formGroup.controls).forEach((key) => {
      supportedFields.push(formGroup.get(key).field)
    })
    const defaults = {}
    supportedFields.forEach((field) => {
      let fieldDefaultValue: string | string[] = ''
      if (!field) {
        return
      }
      if (field?.type === 'phone' && !!field.defaultCountry) {
        fieldDefaultValue = '+' + this.repoService.countries.find((c) => c.name === field.defaultCountry)?.e164.toString()
      }
      if (field?.type === 'radio' || field?.type === 'checkbox') {
        fieldDefaultValue = []
      }
      defaults[field.name] = fieldDefaultValue
    })
    return defaults
  }
  public reset(formGroup: FormGroup, values: { [key: string]: string | number | boolean | string[] } = null) {
    if(!formGroup) return
    if (values && !values['phone'] && formGroup.contains('phone')) values['phone'] = ''
    formGroup.reset(values ? values : this.getDefaultValues(formGroup), { emitEvent: true })
    Object.keys(formGroup.controls).forEach((key) => {
      formGroup.get(key).setErrors(null)
    })
  }
  public createAllForms() {
    const formGroups = new Map<string, FormGroup>()
    Object.keys(this.headerService.activeEvent.eventDetails.forms).forEach((key: string) => {
      const form: MiceForm = this.getForm(key)
      formGroups.set(key, this.createForm(form))
    })
    return formGroups
  }
  public createForm(form: MiceForm): FormGroup {
    if (!form?.formElements) return
    const formGroup = new FormGroup({})
    const supportedFields = this.getSupportedFields(form?.formElements)
    supportedFields.forEach((field: Field, index: number) => {

      field.suggestions = field.type === 'country' ? form.suggestions?.country : []
      field.autocomplete = field.type === 'email' ? form.suggestions?.email ?? [] : []
      field.defaultCountry = form.event.country
      formGroup.addControl(field.name, this.createControl(field, index))
    })
    return formGroup
  }
  public createControl(field: Field, index: number): FormControl {
    let fieldDefaultValue = ''
    if (field.type === 'phone' && !!field.defaultCountry) {
      fieldDefaultValue = '+' + this.repoService.countries.find((c) => c.name === field.defaultCountry)?.e164.toString()
    }
    const formControl = new FormControl(fieldDefaultValue, {
      validators: this.resolveSyncValidators(field)
    })
    formControl.field = field;
    formControl.component = this.resolveComponent(field)
    formControl.focused = index === 0
    return formControl
  }

  public getForm(form: string): MiceForm {
    const forms = this.headerService.activeEvent.eventDetails.forms
    const miceForm: MiceForm = forms[form]
    this._checkForGender(miceForm?.formElements)
    if (miceForm) {
      miceForm.suggestions = {
        country: this.headerService.activeEvent.eventDetails.suggestedCountries,
        email: this.headerService.activeEvent.eventDetails.suggestedDomains
      }
      miceForm.formElements.forEach((fe) => (fe.formSlug = miceForm.slug))
      return miceForm
    }
  }

  private _checkForGender(formElements: Fields): void {
    if(!formElements) return
    formElements.forEach((element) => {
      if (element.type === 'gender') {
        this.hasGender = true
      }
    })
  }

  public register(values, print = false, method: string = undefined, note: string = undefined, hasOnSitePayment: boolean = false): Observable<RegistrationDocument> {
    const ValueKeys = Object.keys(values.fields)
    ValueKeys.forEach(element => {
      if(element.startsWith('city')){
        values.fields[element] = {
          city: values.fields[element].split(', ')[0],
          country: values.fields[element].split(', ')[values.fields[element].split(', ').length - 1],
          formatted_address: values.fields[element],
        };
      }
    });
    if (values?.fields?.fullname && typeof values?.fields?.fullname === 'string') {
      // Salutation detection algorithm
      const salutation = this.separateSalutation(values.fields.fullname)
      const salutationLength = salutation ? salutation?.split(' ').length : 0

      // Name detection algorithm
      const namesArray = this.separateNames(values.fields.fullname).slice(salutationLength)

      values.fields.fullname = {
        salutation: salutation ? salutation : null,
        first_name: startCase(namesArray[0]),
        mid_name: startCase(namesArray.slice(1, namesArray.length - 1).join(' ')) || null,
        last_name: startCase(namesArray[namesArray.length - 1])
      }
    }

    // Set payment method and its note
    if (method && hasOnSitePayment) values.payment_method = method
    if (note) values.notes = note
    values.cart = localStorage.getItem('micetribe_cart')

    const crewHeaders = new HttpHeaders({
      'CREW-ID': this.auth.user.token,
      'CREW-NAME': this.auth.user.name || this.auth.user.email
    })
    if (print) {
      crewHeaders.append('BADGE-PRINTED', '1')
    }
    return (!values.id
      ? this.http.post(`${environment.api}/v3/registrations/process/create`, values, {
        headers: crewHeaders
      })
      : this.http.patch(`${environment.api}/v3/registrations/process/update`, values, {
        headers: crewHeaders
      })
    ).pipe(map((result: { document: RegistrationDocument }) => result.document)).pipe(res=>{
      localStorage.removeItem('micetribe_cart')
      return res
    })
  }

  public addMember(member): Observable<RegistrationDocument> {
    const crewHeaders = new HttpHeaders({
      'CREW-ID': this.auth.user.token,
      'CREW-NAME': this.auth.user.name || this.auth.user.email
    })
    return this.http
      .patch(
        `${environment.api}/v3/registrations/process/update`,
        {
          id: member.parentId,
          new_members: member.new_members,
          workspace: member.workspace,
          event: member.event,
          form: member.form,
          fields: []
        },
        { headers: crewHeaders }
      )
      .pipe(map((result: { document: RegistrationDocument; new: RegistrationDocument[] }) => result.new[0]))
  }

  public patchPrintRecord(registrationId) {
    const crewHeaders = new HttpHeaders({
      'CREW-ID': this.auth.user.token,
      'CREW-NAME': this.auth.user.name || this.auth.user.email,
      token: this.auth.user.token,
      appId: '2'
    })
    return this.http.patch(
      `${environment.api}/v3/registrations/print/${this.headerService.workspaceSlug}/${this.headerService.eventSlug}/${registrationId}`,
      {},
      { headers: crewHeaders }
    )
  }

  // public patchPrintRecords(registrationIds) {
  //   const crewHeaders = new HttpHeaders({
  //     'CREW-ID': this.auth.user.token,
  //     'CREW-NAME': this.auth.user.name || this.auth.user.email,
  //     token: this.auth.user.token,
  //     appId: '2'
  //   })
  //   return this.http.patch(
  //     `${environment.api}/v3/registrations/print/${this.headerService.workspaceSlug}/${this.headerService.eventSlug}`,
  //     { registrationIds },
  //     { headers: crewHeaders }
  //   )
  // }

  getSupportedFields(fields: Fields) {
    return fields.filter((field) =>
      [
        'name',
        'email',
        'phone',
        'organization',
        'job_title',
        'radio',
        'country',
        'input',
        'checkbox',
        'numeric',
        'url',
        'datetime',
        'upload',
        'gender',
        'age_group',
        'line_separator',
        'info_line',
        'warn_line',
        'star_rating',
        'id_passport_no',
        'autocomplete',
        'city'
      ].includes(field.type)
    )
  }
  private resolveComponent = (field: Field): Type<ControlAttribute> => {
    return {
      name: FullnameComponent,
      email: EmailComponent,
      phone: PhoneComponent,
      organization: OrganizationComponent,
      job_title: JobTitleComponent,
      radio: RadioComponent,
      country: CountryComponent,
      input: PlainTextComponent,
      checkbox: RadioComponent,
      numeric: NumericComponent,
      url: UrlComponent,
      datetime: DateComponent,
      upload: UploadComponent,
      gender: RadioComponent,
      age_group: RadioComponent,
      line_separator: BreakLineComponent,
      info_line: InformationMessageComponent,
      warn_line: WarningMessageComponent,
      star_rating: StarRatingComponent,
      city: CityFieldComponent,
      id_passport_no: IdentityComponent,
      autocomplete: AutocompeleteComponent
    }[field.type]
  }

  private resolveSyncValidators = (field: Field) => {
    let validators: any[] = field.isRequired >= 2 && !this.hasSpecialRequiredValidator(field) ? [Validators.required] : []
    validators.push(...this.getValidator(field))
    return validators
  }

  private getValidator(field: Field) {
    switch (field.type) {
      case 'name':
        return [MinWordsValidator.validateWith({ words: 2 }, this)]
      case 'email':
        return [EmailValidator.validate]
      case 'url':
        return [UrlValidator.validate]
      case 'phone':
        return field.isRequired
          ? [
            RequiredPhoneValidator.validateWith({ defaultCountry: field.defaultCountry }),
            PhoneValidator.validateWith({ defaultCountry: field.defaultCountry })
          ]
          : [PhoneValidator.validateWith({ defaultCountry: field.defaultCountry })]
      case 'checkbox':
        return field.isRequired ? [SelectionValidator] : []
      default:
        return []
    }
  }
  private resolveAsyncValidators = (field: Field): AsyncValidatorFn[] => {
    const validators = []
    validators.push(
      {
        name: [],
        email: [],
        phone: [],
        organization: [],
        job_title: [],
        radio: [],
        country: [],
        input: [],
        checkbox: [],
        numeric: [],
        url: [],
        datetime: [],
        upload: [],
        gender: [],
        age_group: []
      }[field.type]
    )
    return validators
  }
  private hasSpecialRequiredValidator = (field: Field): Boolean => {
    return {
      name: false,
      email: false,
      phone: true,
      organization: false,
      job_title: false,
      radio: false,
      country: false,
      input: false,
      checkbox: true,
      numeric: false,
      url: false,
      datetime: false,
      upload: false,
      gender: false,
      age_group: false,
      line_separator: false,
      info_line: false,
      warn_line: false,
      star_rating: false,
      id_passport_no: false
    }[field.type]
  }
  public setErrors(form: FormGroup, errors: { error: { data: { [key: string]: string[] } } }) {
    const formKeys: string[] = Object.keys(form.controls)
    const subForms: FormGroup[] = []
    formKeys.forEach((e: string) => {
      if (e.includes('_members')) {
        const members = form.get(e) as FormArray
        members.controls.forEach((formGroup: FormGroup) => {
          subForms.push(formGroup)
        })
      }
    })
    if (!!subForms.length) {
      subForms.forEach((sub) => this.setErrors(sub, errors))
    }
    if (errors && formKeys) {
      formKeys.forEach((key) => {
        if (errors.error.data.hasOwnProperty(key)) {
          const control: AbstractControl = form.get(key)
          control.setErrors({
            server: errors.error.data[key][0]
          })
        }
      })
    }
  }
  public settingsChanged: Subject<Settings> = new Subject<Settings>()
  private _settings: Settings = {
    displayHotkeys: true,
    autoPrint: false,
    clearAfterRegister: true,
    viewRecent: false
  }
  public get settings(): Settings {
    return this._settings
  }
  public set settings(value) {
    this._settings = value
    this.settingsChanged.next(this._settings)
  }
  public getDTCMEnabledRegistration(
    registrationId
  ): Observable<{ document: RegistrationDocument; dtcmResponse: any; dtcmPayment: boolean }> {
    return this.http.post<{ document: RegistrationDocument; dtcmResponse: any; dtcmPayment: boolean }>(
      `${environment.api}/v3/registrations/process/get/${this.headerService.workspaceSlug}/${this.headerService.eventSlug}/${registrationId}`,
      {}
    )
  }
  // Salutation detection algorithm
  separateSalutation(value: string) {
    if (typeof value !== 'string') return
    const valueArray = value.split(' ')
    const salutation = []
    for (let i = 0; i < valueArray.length; i++) {
      if (!salutations.includes(valueArray[i].toLowerCase().replace(/\./g, ''))) break

      salutation.push(valueArray[i][0].toUpperCase() + valueArray[i].slice(1))
    }
    return salutation.map((w) => w.replace(/\./g, '') + '.').join(' ')
  }

  // FullName detection algorithm
  separateNames(value: string) {
    if (typeof value !== 'string') return
    const namesArray = value.split(' ')
    for (let i = 0; i < namesArray.length; i++) {
      if (namesArray[i] === '.') namesArray.splice(i, 1)
      if (combinedNouns.includes(namesArray[i].toLowerCase())) {
        namesArray.splice(i, 2, !namesArray[i + 1] ? namesArray[i] + ' ' : namesArray[i] + ' ' + namesArray[i + 1])
        i = -1
      }
    }
    return namesArray
  }

  genderDetection(name: string): Observable<{ gender: string }> {
    return this.activeGenderDetection ? this.http.get<{ gender: string }>(`${environment.registrationsApi}process/gender/${name}`) : of({ gender: 'not_detected' })
  }

  setGender(gender: string) {
    // get the current value of _gender$
    let currentGender: string
    this._gender$.subscribe((value) => (currentGender = value)).unsubscribe()

    // check if the current value is null or not equal to the new value
    if (currentGender === null || currentGender !== gender) {
      this._gender$.next(gender)
    }
  }

  getGender() {
    return this._gender$.asObservable()
  }

  listResource(api: string) {
    return this.http.get(api)
  }

  searchCity(query) {
    return this.http.get(`${environment.mapbox_geocodig}mapbox.places/${query}.json?access_token=${environment.mapbox_access_token}`)
  }

  public get eventTimeZone(): string {
    const event = this.headerService.activeEvent;
    if (!event) {
      return
    }
    return event.eventDetails.timezone_offset_string
  }
}
