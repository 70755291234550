import { AbstractControl } from '@angular/forms'
import { AbstractValidator } from './abstract.validator'
import { ValidationTypes } from './validation.types'
import { FormsService } from '../services/forms.service'

export class MinWordsValidator implements AbstractValidator {
  static type = ValidationTypes.validateWith
  static validateWith({ words = 1 }: { words: number }, forms: FormsService) {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null
      }
      // Salutation detection algorithm
      const salutation = forms.separateSalutation(control.value)

      // FullName detection algorithm
      const namesArray = forms.separateNames(control.value)

      const value = namesArray
        .slice(salutation ? salutation?.split(' ')?.length : 0)
        .map((name) => name.replace(/ /g, ''))
        .join(' ')
        .toLowerCase()
        .trim()

      return /([\u0621-\u064A\u066E-\u06D5a-zA-Zà-žÀ-Ž\.]{2,}\s)*([\u0621-\u064A\u066E-\u06D5a-zA-Zà-žÀ-Ž]{2,}\s)+[\u0621-\u064A\u066E-\u06D5a-zA-Zà-žÀ-Ž]{2,}/g.test(
        value.trim()
      )
        ? null
        : { minWords: `Please complete the full name` }
    }
  }
}
