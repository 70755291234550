const salutations: string[] = [
  'avm',
  'admiraal',
  'admiral',
  'admrl',
  'air',
  'cdre',
  'commodore',
  'marshal',
  'vice',
  'alderman',
  'alhaji',
  'ambassador',
  'baron',
  'barones',
  'brg',
  'general',
  'brig',
  'gen',
  'brgdr',
  'brigadier',
  'brother',
  'canon',
  'capt',
  'captain',
  'cardinal',
  'cdr',
  'chief',
  'cik',
  'cmdr',
  'col',
  'dr',
  'colonel',
  'commandant',
  'commander',
  'commissioner',
  'comte',
  'comtessa',
  'congressman',
  'congresswoman',
  'conseiller',
  'consul',
  'conte',
  'contessa',
  'corporal',
  'councillor',
  'count',
  'countess',
  'crown',
  'prince',
  'princess',
  'dame',
  'datin',
  'dato',
  'datuk',
  'seri',
  'deacon',
  'deaconess',
  'dean',
  'dhr',
  'dipl',
  'ing',
  'doctor',
  'dott',
  'sa',
  'dra',
  'drs',
  'embajador',
  'embajadora',
  'en',
  'encik',
  'eng',
  'eur',
  'exma',
  'sra',
  'exmo',
  'sr',
  'fo',
  'father',
  'first',
  'lieutient',
  'officer',
  'flt',
  'lieut',
  'flying',
  'fr',
  'frau',
  'fraulein',
  'fru',
  'generaal',
  'governor',
  'graaf',
  'gravin',
  'group',
  'grp',
  'h',
  'e',
  'm',
  'r',
  'hajah',
  'haji',
  'hajim',
  'her',
  'highness',
  'majesty',
  'herr',
  'high',
  'his',
  'holiness',
  'hon',
  'hr',
  'hra',
  'ir',
  'jonkheer',
  'judge',
  'justice',
  'khun',
  'wing',
  'kolonel',
  'lady',
  'lcda',
  'lic',
  'lord',
  'lt',
  'ml',
  'mr',
  'madame',
  'mademoiselle',
  'maj',
  'major',
  'master',
  'mevrouw',
  'miss',
  'mister',
  'mlle',
  'mme',
  'monsieur',
  'monsignor',
  'mrs',
  'ms',
  'mstr',
  'nti',
  'pastor',
  'president',
  'princesse',
  'prinses',
  'prof',
  'sir',
  'professor',
  'phd',
  'puan',
  'sri',
  'rabbi',
  'rear',
  'rev',
  'mother',
  'reverend',
  'rva',
  'senator',
  'sergeant',
  'sheikh',
  'sh',
  'sig',
  'na',
  'ra',
  'sister',
  'sqn',
  'ldr',
  'd',
  'srta',
  'tan',
  'tengku',
  'teuku',
  'than',
  'puying',
  'the',
  'very',
  'toh',
  'tun',
  'viscount',
  'viscountess',
  'wg',
  'wng'
]

const combinedNouns: string[] = ['al', 'el', 'abd', 'abdel', 'abdal', 'abdol', 'abdul', 'abd el', 'abd al', 'ابو', 'أبو']

const maleSalutations = [
  'admiraal',
  'admiral',
  'admrl',
  'air',
  'cdre',
  'commodore',
  'marshal',
  'alderman',
  'alhaji',
  'baron',
  'brg',
  'general',
  'brig',
  'gen',
  'brgdr',
  'brigadier',
  'brother',
  'cardinal',
  'cdr',
  'chief',
  'cmdr',
  'col',
  'commandant',
  'commissioner',
  'conte',
  'corporal',
  'councillor',
  'count',
  'crown',
  'prince',
  'deacon',
  'dean',
  'first',
  'lieutient',
  'flt',
  'lieut',
  'flying',
  'fr',
  'generaal',
  'governor',
  'graaf',
  'group',
  'grp',
  'haji',
  'hajim',
  'highness',
  'his',
  'holiness',
  'hon',
  'herr',
  'high',
  'jonkheer',
  'judge',
  'justice',
  'khun',
  'kolonel',
  'lord',
  'lt',
  'ml',
  'mr',
  'mister',
  'major',
  'master',
  'monsieur',
  'monsignor',
  'pastor',
  'prof',
  'sir',
  'professor',
  'rabbi',
  'rear',
  'rev',
  'reverend',
  'senator',
  'sergeant',
  'sheikh',
  'sh',
  'sig',
  'sister',
  'sqn',
  'ldr',
  'd',
  'tan',
  'tengku',
  'teuku',
  'than',
  'the',
  'very',
  'toh',
  'tun',
  'viscount',
  'wg',
  'wng',
  'wing'
]

const femaleSalutations = [
  'barones',
  'cik',
  'comtessa',
  'congresswoman',
  'consul',
  'contessa',
  'countess',
  'crown',
  'princess',
  'dame',
  'datin',
  'deaconess',
  'dhr',
  'dra',
  'embajadora',
  'frau',
  'fraulein',
  'fru',
  'gravin',
  'hajah',
  'hra',
  'lady',
  'madame',
  'mademoiselle',
  'mevrouw',
  'miss',
  'mlle',
  'mme',
  'mrs',
  'ms',
  'nti',
  'princesse',
  'prinses',
  'puan',
  'sri',
  'sheikha',
  'srta',
  'viscountess',
  'her'
]

export { femaleSalutations, maleSalutations, salutations, combinedNouns }
