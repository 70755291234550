export const agencepxPowerofinnovation = (settings, qrcode) => ({
  card: `     
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=${ encodeURI(settings.font) }:wght@400;700;800;900&display=swap" rel="stylesheet">

    <div class="badge-container">
      <div class="info-container">
          ${ settings.persona.enabled ? '<div class="persona">#_PERSONA_#</div>' : ''}

          ${ 
            settings.fields.map((field: string) => {
              return settings[field] && settings[field].enabled ? `<div class="${ field }">#_${ field.toUpperCase() }_#</div>` : ''
            }).join('')
          }

          ${
            settings.qr.enabled
              ? `<div class="qr-code">${qrcode.innerHTML}</div> ${settings.qr.showText ? '<div class="qr-text">#_BARCODE_#</div>' : ''}`
              : ''
          }

          ${
            settings.dtcm.enabled
              ? `#_DTCM_BARCODE_IMAGE_# ${settings.dtcm.showText ? '<div class="dtcm-text">#_DTCM_BARCODE_#</div>' : ''}`
              : ''
          }
      </div>
    </div>
  `,
  style: `
    <style>
      * {
        font-family: '${ settings.font }';
        border-radius: 0;
        margin: 0;
        padding: 0;
        font-weight: bold;
        visibility: visible;
        color: #000;
        text-align: center;
      }

      .badge-container {
        /* width: ${ settings.width }mm !important; */
        height: ${ settings.height }mm !important;
        margin-right: auto !important;
        margin-left: auto !important;
        text-align: center !important;
        justify-content: center !important;
        transform: rotateZ(180deg) !important;
      }

      .info-container {
          display: flex !important;
          flex-direction: column !important;
          align-items: center !important;
          justify-content: center !important;
          padding-top: ${ settings.topPadding }mm !important;
      }

      .persona {
        font-weight: 700 !important;
        font-size: ${ settings.persona.fontSize }mm !important;
        line-height: ${ settings.persona.fontSize }mm !important;
        padding-bottom: ${ settings.persona.bottomPadding }mm !important;
        margin: 0 !important;
        overflow: hidden !important;
        color: #000000 !important;
      }

      ${
        settings.fields.map((field: string) => {
          if (settings[field].enabled) {
            return `.${field} {
              ${ settings[field].textTransformEnabled ? 'text-transform: capitalize !important;' : '' }
              font-size: ${ settings[field].fontSize }mm !important;
              line-height: ${ settings[field].fontSize }mm !important;
              padding-bottom: ${ settings[field].bottomPadding }mm !important;
              font-weight: bold !important;
              margin: 0 !important;
              overflow: hidden !important;
              color: #000000 !important;
            }`
          }
        }).join('')
      }

      .qr-text {
        text-transform: capitalize !important;
        font-size: ${ settings.qr.fontSize }mm !important;
        line-height: ${ settings.qr.lineHeight }mm !important;
        padding-bottom: ${ settings.qr.bottomPadding }mm !important;
        margin: 0 !important;
        overflow: hidden !important;
        font-weight: bold !important;
        color: #000000 !important;
      }
    
      .qr-code {
        padding-top: ${ settings.qr.topPadding }mm !important;
        padding-bottom: ${ settings.qr.bottomPadding }mm !important;
        width: ${ settings.qr.width }mm !important;
      }
    
      .dtcm-text {
        text-transform: capitalize !important;
        font-size: ${ settings.dtcm.fontSize }mm !important;
        line-height: ${ settings.dtcm.lineHeight }mm !important;
        padding-bottom: ${ settings.dtcm.bottomPadding }mm !important;
        margin: 0 !important;
        overflow: hidden !important;
        font-weight: bold !important;
        color: #000000 !important;
      }

      .dtcm-code {
        padding-top: ${ settings.dtcm.topPadding }mm !important;
        padding-bottom: ${ settings.dtcm.bottomPadding }mm !important;
        height: ${ settings.dtcm.height }mm !important;
      }

      .print-page {
          display: block
      }

      .page-break {
          display: block;
          page-break-before: always
      }

      @media print {
          .page-break {
              display: block;
              page-break-before: always
          }
      }
    </style>
  `
})