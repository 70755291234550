import { Component } from '@angular/core';

@Component({
  selector: 'mice-printing-dtcm-print-wait',
  templateUrl: './dtcm-print-wait.component.html',
  styleUrls: ['./dtcm-print-wait.component.scss']
})
export class DtcmPrintWaitComponent {

  constructor() { }

}
