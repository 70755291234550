export const qga_cbqm27 = (settings, qrcode, fields: Object[] = null) => ({
    card: `
            <div class="badge-container">
                 <div class="info-container">
                        <div style="height: 100%;">
                            <div class="profile_qr">
                                <div class="wrapper-profile-qr">
                                ${`<div class="qr-code">${qrcode.innerHTML}</div>`}
                                    <div class="profile_pic">
                                    <img class="user-pic" src="#_UPLOAD_#" alt="">
                                    </div>
                                </div>
                            </div>

                            <div id="fullname-wrapper" class="fullname_section">
                                <p id="fullname">#_FULLNAME_#</p>
                            </div>
                        </div>
                    </div>
                </div>
                <script>
                function checkOverflow() {
                    const div = document.getElementById('fullname-wrapper');
                    const text = document.getElementById('fullname');
                    if (text.offsetHeight > div.offsetHeight || text.offsetWidth > div.offsetWidth) {
                        let fontSize = parseInt(window.getComputedStyle(text).fontSize);
                        while (text.offsetHeight > div.offsetHeight || text.offsetWidth > div.offsetWidth) {
                            fontSize -= 1;
                            text.style.fontSize = fontSize + 'px';
                        }
                    }
                }
                window.addEventListener('load', checkOverflow);
                window.addEventListener('resize', checkOverflow);
                </script>
          `,
    style: `
    <style>
    * {
        font-family: 'D-DIN', sans-serif;
        border-radius: 0;
        margin: 0;
        padding: 0;
        font-weight: 400;
        visibility: visible;
        color: #000;
        text-align: center;
    }

    .mb-2 {
        margin-bottom: 2px !important;
    }

    .badge-container {
        width: 86mm !important;
        height: 123mm !important;
        margin-right: auto !important;
        margin-left: auto !important;
        text-align: center !important;
        justify-content: center !important;
        display: flex;
        transform: rotateZ(180deg) !important;

    }

    .info-container {
        margin-top: 35.38mm;
        height: 63mm;
        width: 66.8mm;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        background-color: white;
    }

    .print-page {
        display: block;
    }

    .page-break {
        display: block;
        page-break-before: always;
    }

    .profile_qr {
        margin-top: 4mm;
    }

    .wrapper-profile-qr {
        display: flex;
        overflow: hidden;
        justify-content: space-around;
    }

    .user-pic {
        height: 25mm;
        max-width: 25mm;
        object-fit: cover;
    }

    .qr {

    }

    .qr-code {
        display: flex;
align-items: center;
justify-content: center;
    }

    .fullname_section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 15mm;
        margin-top: 3mm;
    }

    .fullname_section p {
        font-size: 6.2mm;
    }



    @font-face {
        font-family: 'DIN Next LT Arabic';
        src: url('https://library.micetribe.com/fonts/DIN Next LT Arabic Bold.woff') format('woff');
    }

    @font-face {
        font-family: 'D-DIN';
        src: url('https://library.micetribe.com/fonts/D-DIN.ttf') format('truetype');
    }

    @media print {
        .page-break {
            display: block;
            page-break-before: always;
        }
    }
</style>
          `
})

