import { Component, OnDestroy, OnInit } from '@angular/core'
import { ControlAttribute } from '../../attributes/control.attribute'
import * as faSolid from '@fortawesome/free-solid-svg-icons'
import * as faRegular from '@fortawesome/free-regular-svg-icons'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'cf-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent extends ControlAttribute implements OnInit, OnDestroy {
  private _destroy$ = new Subject()
  /**
   * Rating Varialbes
   */
  rating: number = 0
  starCount: number = 5
  ratingArr: number[] = []
  /**
   * Font Awesome Icons
   */
  faStar: faSolid.IconDefinition = faSolid.faStar
  faEmptyStar: faRegular.IconDefinition = faRegular.faStar

  constructor() {
    super()
  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
    this._destroy$.unsubscribe()
  }

  /**
   * Initialize Component
   */
  ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((currentValue: number) => {
      if(!currentValue) return;
      this.rating = currentValue
    })
    if (this.control.value) this.rating = this.control.value
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index)
    }
  }

  /**
   * Emits when changing Rating
   * @param rating number
   */
  onRateChange(rating: number): void {
    this.rating = rating
    this.control.setValue(rating, { emitEvent: false })
  }

  /**
   * Show icon depends on selected rating
   * @param index number
   * @returns boolean
   */
  showIcon(index: number): boolean {
    return this.rating >= index + 1
  }
}
