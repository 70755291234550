<mat-form-field appearance="outline" class="country-field" [ngClass]="{ empty: !control.value }">
  <mat-label [innerHtml]="control.field.label"></mat-label>
  <input
    [readonly]="loading"
    matInput
    type="text"
    [formControl]="control"
    autocomplete="enable "
    [matAutocomplete]="auto"
    #country
    (keydown.enter)="onEnter($event)"
  />

  <mat-autocomplete #auto="matAutocomplete" [autoActiveFirstOption]="true">
    <mat-option *ngFor="let option of resource | async" [value]="option">
      <span>{{ option }}</span>
    </mat-option>
  </mat-autocomplete>
  <mat-hint>
    <mice-input-shortcuts [control]="control"></mice-input-shortcuts>
  </mat-hint>
  <mat-error>
    {{ errorMessage }}
  </mat-error>
</mat-form-field>
