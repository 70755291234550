import { Component, OnDestroy, OnInit } from '@angular/core'
import { Observable, Subject, of } from 'rxjs'
import { concatMap, map, take, takeUntil } from 'rxjs/operators'
import { ControlAttribute } from '../../attributes/control.attribute'
import { FormsService } from '../../services/forms.service'
import { salutations, femaleSalutations, maleSalutations } from '../../data/fullname-data'
@Component({
  selector: 'cf-fullname',
  templateUrl: './fullname.component.html',
  styleUrls: ['./fullname.component.scss']
})
export class FullnameComponent extends ControlAttribute implements OnInit, OnDestroy {
  private name: string
  private gender: string = ''
  private salutation: string
  private _destroy$ = new Subject()
  constructor(private formsService: FormsService) {
    super()
  }
  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((currentValue: string) => {
      if(!currentValue) return;
      // Salutation detection algorithm
      const salutation = this.formsService.separateSalutation(currentValue).replace(/\./g, '').toLowerCase()
      currentValue = currentValue
        .replace(/\./g, '')
        ?.split(' ')
        .map((w, i) => {
          if (i >= salutation?.split(' ').length) return w
          if (!currentValue) return
          if (salutations.includes(w.toLowerCase())) return w + '.'
          else return w
        })
        .join(' ')

      const capitalizedFirstLetterRegex = /\b[a-z](?!\s)/g
      const nonLettersRegex = /[^a-zA-Z\u00C0-\u00FF\u0621-\u064A\.\s]/g
      const moreThanOneSpaceRegex = / {2,}/g
      const moreThanOneDotRegex = /\.{2,}/g
      currentValue = currentValue
        .replace(nonLettersRegex, '')
        .replace(moreThanOneSpaceRegex, ' ')
        .replace(moreThanOneDotRegex, '.')
        .replace(capitalizedFirstLetterRegex, (letter) => letter.toUpperCase())
      currentValue = currentValue.startsWith(' ') ? currentValue.trim() : currentValue
      this.control.setValue(currentValue, { emitEvent: false })
    })
  }
  formatBlur() {
    this.control.setValue(this.control.value.trim())
    this.processString(this.control.value)
  }
  deleteChar(e) {
    let value: string = e.target.value
    if (value.split('')[value.split('').length - 1] === '.') value = value.slice(0, value.length - 2)
    this.control.setValue(value, { emitEvent: false })
  }

  /**
   * Process the input string, detect and update the gender based on salutations or first name.
   *
   * @param {string} str - The input string
   */
  processString(str: string) {
    const words = str.split(' ')
    const salutations = words.filter((word) => word.endsWith('.')).map((word) => word.toLowerCase().slice(0, -1))
    const nonSalutationWords = words.filter((word) => !word.endsWith('.') && word.length > 1)

    const genderFromSalutation = salutations.length > 0 ? this.salutationGenderDetection(salutations) : 'not_detected'

    if (nonSalutationWords.length > 0 && genderFromSalutation === 'not_detected') {
      of(nonSalutationWords[0])
        .pipe(
          takeUntil(this._destroy$),
          concatMap((name) => this.detectGender(name))
        )
        .subscribe((detectedGender) => {
          this._setGender(detectedGender)
        })
    } else {
      this._setGender(genderFromSalutation)
    }
  }

  private _setGender(gender: string) {
    this.gender = gender !== 'not_detected' ? gender : ''
    this.formsService.setGender(this.gender)
  }

  /**
   * Detect gender based on the first name using an external service.
   *
   * @param {string} name - The first name
   * @returns {Promise<string>} The detected gender or 'not_detected'
   */
  detectGender(name: string): Observable<string> {
    if (this.name === name) {
      return of(this.gender)
    }
    this.name = name.toLowerCase()

    return this.formsService.genderDetection(this.name).pipe(
      map((response) => {
        return response.gender
      })
    )
  }
  /**
   * Detect gender based on the salutation.
   *
   * @param {string[]} genderSalutations - Array of salutations
   * @returns {string} The detected gender or 'not_detected'
   */
  salutationGenderDetection(genderSalutations: string[]): string {
    if (this.salutation === genderSalutations[0]) return this.gender
    // Check if any of the genderSalutations is in maleSet or femaleSet
    const maleSet = new Set(maleSalutations)
    const femaleSet = new Set(femaleSalutations)
    let detectedGender = 'not_detected'

    for (let salutation of genderSalutations) {
      if (maleSet.has(salutation)) {
        detectedGender = 'male'
        break
      } else if (femaleSet.has(salutation)) {
        detectedGender = 'female'
        break
      }
    }

    this.salutation = genderSalutations[0]

    return detectedGender
  }
}

