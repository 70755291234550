import { FormArray, FormControl, FormGroup } from '@angular/forms'
import { FieldValues } from '../models/field-value.model'
import { Field } from '../models/field.model'
export class ControlAttribute {
  public field: Field
  public values?: FieldValues
  public control: FormControl
  public parent: FormGroup | FormArray
  public allowMultipleValues: boolean
  constructor() {}

  public prepare(control: FormControl) {
    this.control = control
    this.field = control.field
    this.prepareMultiSelect()
  }

  public set(value: any) {
    if (!this.allowMultipleValues) {
      this.control.setValue(value)
      return
    }
    const currentValue = this.control.value as string[]
    if (currentValue.includes(value)) {
      currentValue.splice(currentValue.indexOf(value), 1)
    } else {
      currentValue.push(value)
    }
    this.control.setValue(currentValue)
  }
  public toggle(value: any) {
    if (!this.allowMultipleValues) {
      if (this.control.value === value) {
        this.control.setValue('')
      } else {
        this.control.setValue(value)
      }
      return
    }
    const currentValue = this.control.value as string[]
    if (currentValue.includes(value)) {
      currentValue.splice(currentValue.indexOf(value), 1)
    } else {
      currentValue.push(value)
    }
    this.control.setValue(currentValue)
  }

  public is(value: any) {
    if (!this.allowMultipleValues) {
      return typeof(this.control.value) === 'string' ? this.control.value?.toLowerCase()?.trim() === value.toLowerCase().trim() : false
    }
    return this.control.value.includes(value)
  }

  prepareMultiSelect() {
    this.allowMultipleValues = this.control.field.type === 'checkbox'
    if (this.allowMultipleValues && !this.control.value) {
      this.control.setValue([], { onlySelf: true, emitEvent: false })
    }
  }
  get hasErrors() {
    return this.control.invalid && !this.control.touched
  }
  get errorMessage() {
    let error = ''
    if (!this.control.errors) {
      return error
    }
    Object.keys(this.control.errors)
      .filter((k) => this.control.errors.hasOwnProperty(k))
      .map((k) => (this.control.errors[k] === true ? k : this.control.errors[k]))
      .forEach((k) => (error += k))
    return error
  }
}
