export const wise2021Ted = (settings, qrcode) => ({
  card: `
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.cdnfonts.com/css/din-condensed" rel="stylesheet">

    <div class="badge-container">
      <div class="header">
          <img src="https://library.micetribe.com/workspace/qf/wise2021/header-left.svg"
              style="width: 118.01">
          <img src="https://library.micetribe.com/workspace/qf/wise2021/header-right.svg"
              style="width: 99.57">
      </div>
      <div class="content">
          <div class="content-left">
              <div class="name">#_FULLNAME_#</div>
              <div class="title">#_JOB_TITLE_#</div>
              <div class="title">#_ORGANIZATION_#</div>
              <div class="qr-code">${qrcode.innerHTML}</div>
          </div>
          <div class="content-right">
              <div class="profile-picture"><img src="#_PROFILE_PHOTO_URL_#" alt="#_FULLNAME_#"></div>
              <div class="id">id #_ID_#</div>
          </div>
      </div>
      <div class="attendee-type">
          <img src="https://library.micetribe.com/workspace/qf/wise2021/ted-badge.png">
      </div>
      <div class="footer">
          <img src="https://library.micetribe.com/workspace/qf/wise2021/footer.svg">
      </div>
    </div>
  `,
  style: `
    <style>
      body {
          margin: 0
      }

      .badge-container {
          height: 122mm;
          max-height: 122mm;
          width: 86mm;
          margin: 0;
          position: relative;
          color: #292627
      }

      .header {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin: 0 35px 21px;
          padding-top: 8mm;
      }

      .header .header-right {
          text-align: right
      }

      .header .header-left {
          text-align: left
      }

      .content {
          display: flex;
          justify-content: space-between;
          margin: 0 35px
      }

      .content-left {
          max-width: 135px
      }

      .name {
          font-family: 'Montserrat', sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 1.5;
          text-transform: uppercase
      }

      .title {
          font-family: 'Montserrat', sans-serif;
          font-weight: 400;
          font-size: 10px;
          line-height: 1.4;
          text-transform: capitalize;
          margin: 0.3em 0 0 0;
          max-height: calc(1.4em * 2);
          overflow: hidden
      }

      .qr-code > img {
          width: 87px;
          height: 87px
      }

      .profile-picture img {
          margin: 6px 0;
          height: 119.2px;
          width: 95.8px;
          object-fit: cover
      }

      .attendee-type {
          position: absolute;
          bottom: 22mm
      }

      .attendee-type > span {
          top: calc((68.425px - 1em) / 2);
          position: absolute;
          display: flex;
          justify-content: center;
          width: 100%;
          font-size: 28px;
          font-family: 'DIN Condensed', sans-serif;
          text-transform: uppercase
      }

      .footer {
          position: absolute;
          bottom: 6mm;
          display: flex;
          justify-content: center;
          width: 100%;
      }

      .id {
          padding: 10px 0;
          font-family: 'Montserrat', sans-serif;
          font-weight: 400;
          font-size: 11px;
          line-height: 1.4;
          text-transform: uppercase
      }

      .contributor, .speaker, .vip {
          fill: #006072;
      }

      .vip {
          color: #006072 !important;
      }

      .wise_ceo, .wise_staff, .wise_team, .staff {
          fill: #97caeb;
      }

      .delegate {
          fill: #cbb0cf;
      }

      .eaa, .eaa_team, .eaa_speaker {
          fill: #ffb98e;
      }

      .media {
          fill: #fcd672;
      }

      .ted, .ted_speaker {
          fill: #fe160d;
      }
    </style>
  `
})
