<div class="upload d-flex flex-column">
  <label [innerHtml]="control.field.label"></label>
  <input type="file" (change)="uploadFile($event.target.files)" #uploadField />

  <div class="d-flex">
    <button mat-raised-button *ngIf="hasFile && !uploading" type="button" role="button" name="Change" (click)="triggerFileSelect()">
      CHANGE
    </button>
    <button mat-raised-button *ngIf="!hasFile && !uploading" type="button" role="button" name="Upload file" (click)="triggerFileSelect()">
      UPLOAD
    </button>
    <button mat-raised-button *ngIf="uploading" type="button" role="button" name="Uploading a file..." (click)="triggerFileSelect()">
      UPLOADING ({{ uploadPercentage }}%)
    </button>
    <img
      class="thumbnail"
      *ngIf="hasFile && !uploading && isImage(control.value)"
      [src]="control.value"
      alt="Uploaded image"
      (click)="openImage(image)"
    />
    <img
      class="thumbnail"
      loading="lazy"
      (click)="openFile(control.value)"
      *ngIf="hasFile && !uploading && !isImage(control.value)"
      src="https://library.micetribe.com/icons/file.svg"
      alt="Uploaded file"
    />
  </div>
</div>

<ng-template #image>
  <div class="modal-header">
    <h4 class="modal-title"></h4>
    <mat-icon aria-hidden="false" aria-label="Close" style="cursor: pointer" (click)="close()">close</mat-icon>
  </div>
  <div class="modal-body d-flex flex-column align-items-center justify-content-center" #modal>
    <mat-spinner *ngIf="isRotatingImage" aria-label="Loading your past events"></mat-spinner>
    <img *ngIf="!isRotatingImage" [src]="control.value" alt="Image" />
    <span class="rotate-button" *ngIf="!customImportedData()" matTooltip="Rotate the image counter clockwise" (click)="rotateImage()">
      <mat-icon>rotate_90_degrees_ccw</mat-icon>
    </span>
  </div>
</ng-template>
