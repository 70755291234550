import { AbstractControl } from '@angular/forms'
import { AbstractValidator } from './abstract.validator'
import { ValidationTypes } from './validation.types'

export class SelectionValidator implements AbstractValidator {
  static type = ValidationTypes.validate
  static validate(control: AbstractControl) {
    if (!control.value) {
      return null
    }
    return Array.isArray(control.value) && control.value.length ? null : { selection: `A selection is required` }
  }
}
